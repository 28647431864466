import React, { useReducer } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MapPage from "./components/Map/MapPage";
import RoomPage from "./components/Room/RoomPage";
import ProgressModal from "./components/Modals/ProgressModal/ProgressModal";
import VideoModal from "./components/Modals/VideoModal/VideoModal";
import SideNav from "./components/SideNav/SideNav";
import reducer from "./store/reducer";
import { initialState } from "./store/initialState";
import { AppContext } from "./store/context";
import "./App.css";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className="App">
      <AppContext.Provider value={{ state, dispatch }}>
        <Router>
          <Switch>
            <Route path="/:room" children={<RoomPage />}>
              <RoomPage />
            </Route>
            <Route path="/">
              <MapPage />
            </Route>
          </Switch>
          <SideNav />
        </Router>
        <ProgressModal />
        <VideoModal />
      </AppContext.Provider>
    </div>
  );
}

export default App;
