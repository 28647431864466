import React, {useEffect, useState, useRef, useContext} from 'react';
import * as THREE from "three";
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import {DDSLoader} from 'three/examples/jsm/loaders/DDSLoader';
import {Interaction} from 'three.interaction';
import './ThreeRoom.css';
import TWEEN from '@tweenjs/tween.js';
import cms from '../../../cmsservice';
import MarkerModal from "../../Modals/MarkerModal/MarkerModal";
import CompletedModal from "../../Modals/CompletedModal/CompletedModal";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AppContext } from "../../../store/context";
import ResourceTracker from "../../../ResourceTracker";
import MetaTags from 'react-meta-tags';
import { constants } from '../../Common/constants';
var classNames = require("classnames");

const dragFactor = 0.2;
//Bomb 3D code below
//Three has its own event loop, separate from React. This is the Three state object.
const tc = {
  renderer: new THREE.WebGLRenderer({}),
  scene: new THREE.Scene(),
  camera: new THREE.PerspectiveCamera(75, window.innerWidth/window.innerHeight, 0.1, 10000),
};
cms.getEntry('4gbEkpYveOL3YdTF0t7qpg').then(({ fields: { hotSeasonStartDate, hotSeasonEndDate }}) => {
  if (hotSeasonStartDate && hotSeasonEndDate) {
    const dates = [hotSeasonStartDate, hotSeasonEndDate].reduce((acc, itm) => {
      let d = new Date(itm);
      d.setFullYear(new Date().getFullYear());
      return [ ...acc, d.getTime() ];
    }, []);
    const now = new Date().getTime();
    if (dates[0] < now && dates[1] > now) tc.season = 'hot';
    else tc.season = 'cold';

  }
  else {
    tc.season = 'cold';
  }
});
let isInitialLoad = true;
let toastCount = 0;

//GENERATES 3D ROOM
function ThreeRoom({ roomID, setCardInfo, setLoading, markerClick }) {
  const [selectedRoom, setSelectedRoom] = useState();
  const [highlightActive, sethighlightActive] = useState();
  const [animationDescriptionActive, setAnimationDescriptionActive] = useState(false);
  const [animationDescription, setAnimationDescription] = useState("Bright White");
  const [animationTitle, setAnimationTitle] = useState("");
  const [filterHasBeenInitClicked, setFilterHasBeenInitClicked] = useState(false);
  const mount = useRef(null);
  const { state, dispatch } = useContext(AppContext);
  const resourceTracker = new ResourceTracker();
  const track = resourceTracker.track.bind(resourceTracker);
  const [seoDescription, setSeoDescription] = useState("DTE House");
  const [seoTitle, setSeoTitle] = useState("DTE House");

  useEffect(() => {
    cms.getEntries({'content_type': 'room'}).then(({ items }) => {
      const sR = items.find(room => room.fields.roomId === roomID);

      if (sR) {
        setCardInfo({
          entryID: sR.sys.id,
          markerType: 'room',
        });
        setSelectedRoom(sR.fields);
      }
    });

    //scene & renderer init
    tc.renderer.setSize( window.innerWidth, window.innerHeight );
    tc.renderer.shadowMap.enabled = true;
    tc.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    tc.scene.background = new THREE.Color("rgba(255, 255, 255, 0)");

    //init lets for drag camera look
    tc.isUserInteracting = false;
    tc.windowPointer = {
      radius: 0,
      lon: 0,
      lat: 0,
    };
    tc.downPointer = {
      radius: 0,
      lon: 0,
      lat: 0,
      isOutsideClick: false
    };
    tc.interaction = track(new Interaction(tc.renderer, tc.scene, tc.camera));
    tc.endlessAnimations = [];
    tc.triggerAnimations = [];
    tc.markers = [];
    tc.lastClickedMarker = null;
    tc.moveAnimationComplete = true;
    tc.markerFadeAnimationComplete = true;
    tc.currentFilter = "none";
    tc.camera.target = new THREE.Vector3(tc.camera.rotation.x, tc.camera.rotation.y, tc.camera.rotation.z);
    tc.markersClickable = true;

    window.addEventListener('resize', onWindowResize, false);
    document.addEventListener( 'mousedown', onDocumentMouseDown, false );
    document.addEventListener( 'touchstart', onDocumentTouchDown, false );
		document.addEventListener( 'mousemove', onDocumentMouseMove, false );
		document.addEventListener( 'touchmove', onDocumentTouchMove, false );
		document.addEventListener( 'mouseup', onDocumentMouseUp, false );
    document.addEventListener( 'touchend', onDocumentMouseUp, false );

    if (isInitialLoad){
      animate();
      isInitialLoad = false;
    }

    return () => {
      window.removeEventListener('resize', onWindowResize);
      document.removeEventListener( 'mousedown', onDocumentMouseDown );
      document.removeEventListener( 'touchstart', onDocumentTouchDown );
      document.removeEventListener( 'mousemove', onDocumentMouseMove );
      document.removeEventListener( 'touchmove', onDocumentTouchMove );
      document.removeEventListener( 'mouseup', onDocumentMouseUp );
      document.removeEventListener( 'touchend', onDocumentMouseUp );
    }
  }, [roomID]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mount) mount.current.appendChild(tc.renderer.domElement);
  }, [mount]);

  useEffect(() => {
    if (!selectedRoom) return;
    let modelPath = '';
    if (selectedRoom.roomName === "Exterior" && tc.season === 'cold') {
      //model directory
      modelPath = process.env.PUBLIC_URL +'3D_Assets/patio_winter/';
    } else {
      //model directory
      modelPath = process.env.PUBLIC_URL +'3D_Assets/' + selectedRoom.modelDirectory + '/';
    }

    //init OBJ Loader
    let manager = track(new THREE.LoadingManager());
    manager.addHandler( /\.dds$/i, track(new DDSLoader()) );

    //load room model based on passed parameters
    track(new MTLLoader( manager ))
          .setPath(modelPath)
          .load(selectedRoom.materialName + '.mtl', materials => {
            materials.preload();
            track(new OBJLoader(manager))
              .setMaterials(materials)
              .setPath(modelPath)
              .load(selectedRoom.modelName + '.obj', room => {
                //add shadows to all child objects of model
                room.traverse(child => {
                    if (child instanceof THREE.Mesh) {
                      child.castShadow = true;
                      child.receiveShadow = true;
                      child.material.side = THREE.DoubleSide;
                    }
                });
                room.name = "room";
                if (selectedRoom.modelDirectory === "patio") {
                  room.position.x = -260;
                  room.position.z = 220;
                }
                tc.scene.add( room );
                if (selectedRoom.materialName === "KitchenALL4") {
                  hideObject("KitchenHomeEfficiencyKit");
                }
                modelLoadComplete();
              }, onProgress, onError);
          });

    constants.gmt({
      state,
      dataTrackDetail: constants.analytics.nextroom,
      dataTrackSubdetail: selectedRoom.roomName,
      dataTrackAction: constants.analytics.click,
    });

    return function cleanup() {
      tc.scene.remove.apply(tc.scene, tc.scene.children);

      tc.renderer.dispose();

      resourceTracker.dispose();
    };
  }, [selectedRoom]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterMarkers(state.filterType);
  }, [state.filterType]);

  //finish room init after Model has been loaded
  const modelLoadComplete = () => {

    //set meta info for SEO
    if(selectedRoom.seoDescription != undefined) {
      setSeoDescription(selectedRoom.seoDescription);
    }
    if(selectedRoom.seoTitle != undefined) {
      setSeoTitle(selectedRoom.seoTitle);
    }

    if (selectedRoom.skyBoxActive === true) {
      loadSkyBox(selectedRoom.cameraStartX, selectedRoom.cameraStartZ);
    }
    setLoading(96);

    setUpCamera(selectedRoom.cameraStartX, selectedRoom.cameraStartZ);
    setLoading(97);

    //load markers/hotspots & hide seasonal objects and set up animations
    if (selectedRoom.interactiveObjects) {
      setUpInteractiveObject(selectedRoom.interactiveObjects);
    }
    setLoading(98);

    //load lights
    if (selectedRoom.lights) {
      loadRoomLights(selectedRoom.lights);
    }
    setLoading(99);

    // init first person controls
    initFirstPersonControls(selectedRoom.cameraStartLookX, selectedRoom.cameraStartLookZ, selectedRoom.cameraStartX, selectedRoom.cameraStartZ);
    setLoading(100);

  }

  //progress tracker for room loading
  const onProgress = (xhr) => {
    if ( xhr.lengthComputable ) {
      let percentComplete = xhr.loaded / xhr.total * 95;
      setLoading(Math.round(percentComplete, 2));
    }
    else {
      setLoading(95);
    }
  };

  //error handling for room loading
  const onError = (error) => { console.warn(error); };

  //adds outside enviroment if room has windows
  const loadSkyBox = (cameraStartX, cameraStartZ) => {
    let materialArray = [];
    let texture_ft = track(new THREE.TextureLoader().load(process.env.PUBLIC_URL +'3D_Assets/skybox/px.png'));
    let texture_bk = track(new THREE.TextureLoader().load(process.env.PUBLIC_URL +'3D_Assets/skybox/nx.png'));
    let texture_up = track(new THREE.TextureLoader().load(process.env.PUBLIC_URL +'3D_Assets/skybox/py.png'));
    let texture_dn = track(new THREE.TextureLoader().load(process.env.PUBLIC_URL +'3D_Assets/skybox/ny.png'));
    let texture_rt = track(new THREE.TextureLoader().load(process.env.PUBLIC_URL +'3D_Assets/skybox/pz.png'));
    let texture_lf = track(new THREE.TextureLoader().load(process.env.PUBLIC_URL +'3D_Assets/skybox/nz.png'));
    materialArray.push(track(new THREE.MeshBasicMaterial( { map: texture_ft })));
    materialArray.push(track(new THREE.MeshBasicMaterial( { map: texture_bk })));
    materialArray.push(track(new THREE.MeshBasicMaterial( { map: texture_up })));
    materialArray.push(track(new THREE.MeshBasicMaterial( { map: texture_dn })));
    materialArray.push(track(new THREE.MeshBasicMaterial( { map: texture_rt })));
    materialArray.push(track(new THREE.MeshBasicMaterial( { map: texture_lf })));
    for (let i = 0; i < 6; i++)
      materialArray[i].side = THREE.BackSide;
    let skyboxGeo = track(new THREE.BoxGeometry( 10000, 10000, 10000));
    let skybox = track(new THREE.Mesh( skyboxGeo, materialArray ));
    skybox.position.x = cameraStartX;
    skybox.position.y = 0;
    skybox.position.z = cameraStartZ;
    skybox.rotateY(1.9708);
    skybox.name = "skybox";
    tc.scene.add( skybox );
  }

  //camera init function
  const setUpCamera = (cameraStartX, cameraStartZ) => {
    tc.camera.position.x = cameraStartX;
    tc.camera.position.y = 70;
    tc.camera.position.z = cameraStartZ;
  };

  //initilize all markers based on Rooms array of interactiveObjects
  const setUpInteractiveObject = (interactiveObjects) => {
    //create interactive marker/hotspot for each interactive object
    interactiveObjects.forEach(object => {
      const seasonality = object.fields.isActive &&
        [object.fields.seasonalObjectVisibility, object.fields.seasonalMarkerVisibility].map(v => {
          switch(v) {
            case 'Only Hot Season':
              return 'hot';
            case 'Only Cold Season':
              return 'cold';
            default:
              return 'hot cold';
          }
        }).reduce((acc, itm) => {
          if (itm && itm.indexOf(tc.season) > -1) return acc;
          else return false;
        }, true);

      if (seasonality) {
        //if animation is attached to interactive object it requires more set up. Need to determine if animation always runs or is triggered on click before generating marker
        if (object.fields.animation) {
          cms.getEntry(object.fields.animation.sys.id)
            .then(response => {
              //if animation always runs without a trigger
              if (response.fields.alwaysAnimating === true){
                addEndlessAnimation(object.fields , response);
                addMarker(object.fields, false);
              }
              else {
                addTriggerAnimation(object.fields, response);
                addMarker(object.fields, true);
              }
            });
        }
        else {
          addMarker(object.fields, false);
        }
      }
      //hide object if not in season
      else {
        if (object.fields.modelMeshNames) {
          object.fields.modelMeshNames.forEach(mesh => {
            hideObject(mesh);
          });
        }
      }
    });
  }

  //get child helper function
  const getChildObjectByName = (name, parentObject) => {
    return parentObject.children.find(child => child.name === name);
  }

  const getAllChildrenByName = (name, parentObject) => {
    return parentObject.children.filter(child => child.name === name);
  }

  //loads all lights from a room, determines type and generates appropriate light
  const loadRoomLights = (lights) => {
    lights.forEach(({fields: lightDetails}) => {
      if (lightDetails.type === "ambient") genAmbientLight(lightDetails);
      if (lightDetails.type === "point light") genPointLight(lightDetails);
    });
  }

  //ambient light based on prompts
  const genAmbientLight = (light) => {
    const ambient = track(new THREE.AmbientLight( light.color, light.intensity ));
    ambient.name = light.nameId;
    ambient.originalIntensity = light.intensity;
    ambient.originalLightColor = light.color;
    tc.scene.add(ambient);
  }

  //gens a point light based on prompts
  const genPointLight = (light) => {
    let lightGeometry;

    //determine shape of generated point light
    if (light.geometryType === "flat") {
      lightGeometry = track(new THREE.CylinderGeometry( light.geometryScale, light.geometryScale, .1, 25));
    } else {
      lightGeometry = track(new THREE.SphereBufferGeometry( light.geometryScale, 32, 32));
    }

    let lightMain = track(new THREE.PointLight( light.color, light.intensity, light.maxLightDistance, light.decay));
    lightMain.castShadow = light.castShadow;
    lightMain.position.set( light.positionX, light.positionY, light.positionZ);
    lightMain.add( track(new THREE.Mesh( lightGeometry, track(new THREE.MeshBasicMaterial( { color: light.color } )) ) ));
    lightMain.shadow.camera.near = 1;
    lightMain.shadow.camera.far = 500;
    lightMain.shadow.bias = - 0.005; // reduces self-shadowing on double-sided objects
    lightMain.shadow.mapSize.width = 2048;
    lightMain.shadow.mapSize.height = 2048;
    lightMain.name = light.nameId;
    lightMain.originalIntensity = light.intensity;
    lightMain.originalLightColor = light.color;
    tc.scene.add( lightMain );
  }

  // Start DRAG LOOK CAMERA
  const initFirstPersonControls = (cameraLookX, cameraLookZ, cameraStartX, cameraStartZ) => {
    let startPosition = new THREE.Vector3(cameraLookX, 70, cameraLookZ);
    let currentCamPosition = new THREE.Vector3(cameraStartX, 70, cameraStartZ);
    startPosition = new THREE.Vector3(...Object.values(getNewPointOnVector(currentCamPosition, startPosition)));
    tc.camera.lookAt(startPosition);
    const radius = Math.hypot(...Object.values(startPosition));
    const phi = Math.acos(startPosition.y / radius);
    const theta = Math.atan2(startPosition.z, startPosition.x);
    tc.windowPointer = {
      ...tc.windowPointer,
      radius,
      lon: THREE.Math.radToDeg(theta),
      lat: 90 - THREE.Math.radToDeg(phi),
    };
  }

  //calculate and return a new point on a vector 2 times farther away than p2 from p1
  const getNewPointOnVector = (p1, p2) => {
    let distAway = 200;
    let vector = {x: p2.x - p1.x, y: p2.y - p1.y, z:p2.z - p1.z};
    let vl = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2) + Math.pow(vector.z, 2));
    let vectorLength = {x: vector.x/vl, y: vector.y/vl, z: vector.z/vl};
    let v = {x: distAway * vectorLength.x, y: distAway * vectorLength.y, z: distAway * vectorLength.z};
    return {x: p2.x + v.x, y: p2.y + v.y, z: p2.z + v.z};
  }

  //determine endless animation type and create
  const addEndlessAnimation = (object, animation) => {
    //determine type and animate accordingly
    switch(animation.fields.animationType) {
      case "rotation":
        createRotationAnimation(animation.fields);
        break;
      default:
    }
  }

  //determine trigger animation type and create
  const addTriggerAnimation = (object, animation) => {
    //store animation trigger info for later
    tc.triggerAnimations.push({
      name: object.name,
      animation: animation.fields,
    });
  }

  //util function to create marker geometry, add to scene and set up use interaction
  const addMarker = (marker, triggerAnimationOnMarker) => {
    let markerGroup = track(new THREE.Object3D());
    markerGroup.name = "marker";
    markerGroup.nameID = marker.name;
    markerGroup.markerInfo = marker;

    //make marker Icon
    let iconImg = null;
    let markerColor = null;
    switch(marker.markerType) {
      case "special offers":
        iconImg = process.env.PUBLIC_URL +"img/Icons/objectTypes/specialOffers/specialOffers.png";
        markerColor = 0x2E9CFF;
        break;
      case "smart home":
        iconImg = process.env.PUBLIC_URL +"img/Icons/objectTypes/smartHome/smartHome.png";
        markerColor = 0x103DBC;
        break;
      case "marketplace":
        iconImg = process.env.PUBLIC_URL +"img/Icons/objectTypes/marketplace/marketplace.png";
        markerColor = 0x39AAC8;
        break;
      default:
        iconImg = process.env.PUBLIC_URL +"img/Icons/objectTypes/tips/tips.png";
        markerColor = 0x80D8D3;
        break;
    }

    let backgroundMaterial = track(new THREE.MeshBasicMaterial({color: markerColor}));

    //make marker background
    let backgroundGeometry = track(new THREE.CylinderGeometry( 5, 5, 0.05, 25 ));
    backgroundMaterial.transparent = true;
    backgroundMaterial.opacity = 1.0;
    let background = track(new THREE.Mesh( backgroundGeometry, backgroundMaterial ));
    background.position.x = marker.markerX;
    background.position.y = marker.markerY;
    background.position.z = marker.markerZ;
    background.name = "background";
    markerGroup.add(background);

    //add tail to marker
    var geometry = track(new THREE.CylinderGeometry(0, 1, 2, 30, 1, true));
    var tail = track(new THREE.Mesh(geometry, backgroundMaterial));
    tail.position.x = marker.markerX;
    tail.position.y = marker.markerY;
    tail.position.z = marker.markerZ;
    tail.geometry.applyMatrix( track(new THREE.Matrix4().makeTranslation( 0, 5.8, 0)));
    //attach tail direction variable for rotation later
    if (marker.markerTailPoints === null || marker.markerTailPoints !== undefined) {
      tail.tailPoints = marker.markerTailPoints;
    } else {
      tail.tailPoints = "bottom";
    }
    tail.name = "tail";
    if (marker.markerTailPoints === "none") {
      tail.visible = false;
    }
    markerGroup.add(tail);

    //make icon
    let iconTexture = track(new THREE.TextureLoader().load(iconImg));
    let iconGeometry = track(new THREE.BoxGeometry( 0.08, 4, 4));
    let iconMaterial = track(new THREE.MeshBasicMaterial({
      color: 0xffffff,
      map: iconTexture,
      transparent: true
    }));
    let icon = track(new THREE.Mesh(iconGeometry, new THREE.MeshBasicMaterial(iconMaterial)));
    icon.position.x = marker.markerX;
    icon.position.y = marker.markerY;
    icon.position.z = marker.markerZ;
    icon.name = "icon";
    markerGroup.add(icon);

    //add marker to scene and attach interaction listeners
    tc.scene.add(markerGroup);
    markerGroup.on('pointerdown', () => {
      if (tc.markersClickable) {
        //prevent marker double click
        tc.markersClickable = false;
        setTimeout(function() {
          tc.markersClickable = true;
        }, (1000));

        populateRoomCard(marker);
        cameraToMarker(marker);
        sethighlightActive(false);

        //make previous tracked marker fully visible before altering a new one
        if (tc.lastClickedMarker !== null) {
          tc.lastClickedMarker.children.map( markerPart => {
              tweenOpacity(markerPart, 1.0, 500);
          });
        }

        //fade out marker temporarily when it is clicked && track marker for later
        tc.moveAnimationComplete = false;
        markerGroup.children.map( markerPart => {
          tweenOpacity(markerPart, 0.0, 500);
        });
        tc.lastClickedMarker = markerGroup;

        //if marker triggers an animation
        if(triggerAnimationOnMarker === true) {
          triggerInteractionAnimation(marker.name);
        }
      }
    });
    markerGroup.on('mouseover', () => {
      document.body.style.cursor = "pointer";
    });
    markerGroup.on('mouseout', () => {
      document.body.style.cursor = "default";
    });

    //create marker object for tracking later
    tc.markers.push({
      markerName: marker.name,
      markerID: markerGroup.id,
      markerType: marker.markerType
    });

    markerRotationUpdate();
  }

  //hides a 3d object in the scene when passed a mesh name
  const hideObject = (meshName) => {
    let group = getChildObjectByName("room", tc.scene);
    let object  = getChildObjectByName(meshName, group);
    object.visible = false;
  }

  //rotate all markers so that they face the camera
  const markerRotationUpdate = () => {
    tc.scene.children.forEach(child => {
      if(child.name === "marker") {
        child.children.forEach(element => {
          if (element.name === "icon") {
            element.lookAt(tc.camera.position);
            element.rotateY(1.5708);
          } else if (element.name === "background"){
            element.lookAt(tc.camera.position);
            element.rotateX(1.5708);
          } else if (element.name === "tail") {
            element.lookAt(tc.camera.position);
            element.rotateY(1.5708);

            //rotate tail based on which direction it needs to point
            if (element.tailPoints === "right") {
              element.rotateX(1.5708);
            }
            if (element.tailPoints === "bottom") {
              element.rotateX(3.1416);
            }
            if (element.tailPoints === "left") {
              element.rotateX(4.7124);
            }

          }
        });
      }
    });
  }

  //update/animation loop
  let animate = () => {
    requestAnimationFrame(animate);
    TWEEN.update();
    animateObjects();
    tc.renderer.render( tc.scene, tc.camera );
  };

  //handle resize
  const onWindowResize = () => {
    tc.camera.aspect = window.innerWidth / window.innerHeight;
    tc.camera.updateProjectionMatrix();

    tc.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  //track original mouse positon on mouse down for POV drag look
  const onDocumentMouseDown = (event) => {
    tc.downPointer = {
      ...tc.downPointer,
      x: event.clientX,
      y: event.clientY,
      isOutsideClick: mount.current && !mount.current.contains(event.target)
    };
    setPointerStart();
  }

  //track original mouse positon on touch down Mobile
  const onDocumentTouchDown = (event) => {
    tc.downPointer = {
      ...tc.downPointer,
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
      isOutsideClick: mount.current && !mount.current.contains(event.target)
    };
    setPointerStart();
  }

  //POV look based on mouse movement when down
  const onDocumentMouseMove = (event) => {
    if (tc.isUserInteracting && !tc.downPointer.isOutsideClick) {

      sethighlightActive(false);
      //fade opacity effected marker back in when camera moves
      if (tc.lastClickedMarker !== null && tc.moveAnimationComplete && tc.markerFadeAnimationComplete) {
        tc.markerFadeAnimationComplete = false;
        var opacity = 1.0;
        if (tc.lastClickedMarker.markerInfo.markerType !== tc.currentFilter && tc.currentFilter !== 'none') {
          opacity = 0.4;
        }
        tc.lastClickedMarker.children.map( markerPart => {
          new TWEEN.Tween(markerPart.material)
            .to({opacity: opacity}, 200)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onComplete(() => {
              tc.markerFadeAnimationComplete = true;
            })
            .start()
        });
      }

      tc.windowPointer = {
        ...tc.windowPointer,
        lon: (tc.downPointer.x - event.clientX) * dragFactor + tc.downPointer.lon,
        lat: (event.clientY - tc.downPointer.y) * dragFactor + tc.downPointer.lat,
      };
      changeCameraTarget();
    }
  }

  //POV look based on touch movement (Mobile)
  const onDocumentTouchMove = (event) => {
    if (tc.isUserInteracting && !tc.downPointer.isOutsideClick) {

      sethighlightActive(false);
      //fade opacity effected marker back in when camera moves
      if (tc.lastClickedMarker !== null && tc.moveAnimationComplete && tc.markerFadeAnimationComplete) {
        tc.markerFadeAnimationComplete = false;
        var opacity = 1.0;
        if (tc.lastClickedMarker.markerInfo.markerType !== tc.currentFilter && tc.currentFilter !== 'none') {
          opacity = 0.4;
        }
        tc.lastClickedMarker.children.map( markerPart => {
          new TWEEN.Tween(markerPart.material)
            .to({opacity: opacity}, 200)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onComplete(() => {
              tc.markerFadeAnimationComplete = true;
            })
            .start()
        });
      }

      tc.windowPointer = {
        ...tc.windowPointer,
        lon: (tc.downPointer.x - event.changedTouches[0].pageX) * dragFactor + tc.downPointer.lon,
        lat: (event.changedTouches[0].pageY - tc.downPointer.y) * dragFactor + tc.downPointer.lat,
      };
      changeCameraTarget();
    }
  }

  //Stop POV look when mouse is up
  const onDocumentMouseUp = () => {
    tc.isUserInteracting = false;
  }

  //create rotation object and stored in state for update function
  const createRotationAnimation = (animation) => {
    let group = getChildObjectByName("room", tc.scene);
    let objectToAnimate = getChildObjectByName(animation.animationMesh, group);

    if (animation.animationMesh === "LRCeilingFanBlades") {
      objectToAnimate.geometry.applyMatrix( track(new THREE.Matrix4().makeTranslation( -6.6, 0, -4.6)));
      objectToAnimate.position.x = 6.6;
      objectToAnimate.position.z = 4.6;
    }

    if (animation.animationMesh === "AtticFan") {
      objectToAnimate.geometry.applyMatrix( track(new THREE.Matrix4().makeTranslation( -1, -72.5, 0)));
      objectToAnimate.position.x = 1;
      objectToAnimate.position.y = 73;
    }

    tc.endlessAnimations.push({
      mesh: objectToAnimate,
      animationObject: animation
    });
  }

  //pass entryID of content that will populate room card
  const populateRoomCard = (marker) => setCardInfo({
    entryID: marker.objectInformation.sys.id,
    markerType: marker.markerType,
    markerName: marker.name,
  });

  //smooth move camera to passed position
  const cameraToMarker = (marker) => {
    console.info('Marker', marker);
    var ySkew = 0;
    var zSkew = 0;

    switch (marker.markerTailPoints) {
      case "bottom":
        ySkew = -10;
        break;
      case "top":
        ySkew = 10;
        break;
      case "right":
        zSkew = -10;
        break;
      case "left":
        zSkew = 10;
        break;
      default:
        break;
    }

    console.info('Window', window.innerWidth, window.innerHeight);

    const currentCamPosition = {x: marker.cameraX, y: tc.camera.position.y, z: marker.cameraZ}

    // Slightly tweak the Y-axis positioning with mobile devices.
    let storedMarkerPosition;
    if(window.innerWidth < 1008) {
      storedMarkerPosition = new THREE.Vector3(marker.markerX, (marker.markerY + ySkew - 15), (marker.markerZ + zSkew));
    } else {
      storedMarkerPosition = new THREE.Vector3(marker.markerX, (marker.markerY + ySkew), (marker.markerZ + zSkew));
    }
    
    const newCameraTarget = getNewPointOnVector(currentCamPosition, storedMarkerPosition);
    const markerPosition = new THREE.Vector3(...Object.values(newCameraTarget));
    const startQuaternion = tc.camera.quaternion.clone();

    // final quaternion (with lookAt)
    tc.camera.lookAt(storedMarkerPosition);
    let endQuaternion = tc.camera.quaternion.clone();

    // revert to original quaternion
    tc.camera.quaternion.copy(startQuaternion);

    let time = {t: 0};

    // tween
    new TWEEN.Tween(time)
      .to({t: 1}, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        //make all markers face camera
        markerRotationUpdate();

        //Quaternion is interpolated between two rotations using "slerp"
        THREE.Quaternion.slerp(startQuaternion, endQuaternion, tc.camera.quaternion, time.t);
      })
      .onComplete(() => {
        tc.camera.quaternion.copy(endQuaternion);
        //animate position to location
        new TWEEN.Tween(tc.camera.position)
          .to({
            x: marker.cameraX,
            y: tc.camera.position.y,
            z: marker.cameraZ
          })
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(() => {
            tc.camera.lookAt(storedMarkerPosition);
            //make all markers face camera
            markerRotationUpdate();
          })
          .onComplete(() => {
            tc.camera.lookAt(storedMarkerPosition);
            const radius = Math.hypot(...Object.values(markerPosition));
            const phi = Math.acos(markerPosition.y / radius);
            const theta = Math.atan2(markerPosition.z, markerPosition.x);
            tc.windowPointer = {
              ...tc.windowPointer,
              radius: radius,
              lon: THREE.Math.radToDeg(theta),
              lat: 90 - THREE.Math.radToDeg(phi),
            };

            //adds object highlight when you click on a marker
            sethighlightActive(true);

            //prevent icon from showing back up untill move animation is complete
            tc.moveAnimationComplete = true;

            //make markers face camera
            markerRotationUpdate();
            markerClick(marker);
          })
          .start();
      })
      .start();
  }

  //fires when a trigger marker has been clicked
  const triggerInteractionAnimation = (markerTriggered) => {
    const triggeredAnimation = tc.triggerAnimations.find(animation => animation.name === markerTriggered);
    if (!triggeredAnimation) return;

    //determine type and animate accordingly
    switch(triggeredAnimation.animation.animationType) {
      case "light intensity change":
        changeLightIntensity(triggeredAnimation.animation);
        break;
      case "light color change":
        changeLightColor(triggeredAnimation.animation);
        break;
      default:
        break;
    }
  }

  //updates all object that have an animation attached
  const animateObjects = () => {
    tc.endlessAnimations.forEach(animation => {
      if (animation.animationObject.animationMesh === "AtticFan") {
        animation.mesh.rotation.z += animation.animationObject.rotationSpeed;
      } else {
        //ceiling fan animation
        if (animation.animationObject.animationType === "rotation") {
          if (tc.season === 'hot') {
            animation.mesh.rotation.y -= animation.animationObject.rotationSpeed;
          } else {
            animation.mesh.rotation.y += animation.animationObject.rotationSpeed;
          }
        }
      }
    });
  };

  const setPointerStart = () => {
    tc.isUserInteracting = true;
    tc.downPointer = {
      ...tc.downPointer,
      lon: tc.windowPointer.lon,
      lat: tc.windowPointer.lat,
    };
  }

  const changeCameraTarget = () => {
    const lat = Math.max(-85, Math.min(85, tc.windowPointer.lat));
    const phi = THREE.Math.degToRad(90 - tc.windowPointer.lat);
    const theta = THREE.Math.degToRad(tc.windowPointer.lon);

    let initialCameraTarget = {
      x: tc.windowPointer.radius * Math.sin(phi) * Math.cos(theta),
      y: tc.windowPointer.radius * Math.cos(phi),
      z: tc.windowPointer.radius * Math.sin(phi) * Math.sin(theta),
    };

    tc.camera.target = new THREE.Vector3(...Object.values(initialCameraTarget));
    tc.camera.lookAt(tc.camera.target);

    tc.windowPointer = {
      ...tc.windowPointer,
      lat,
      radius: Math.hypot(tc.camera.target.x, tc.camera.target.y, tc.camera.target.z), //camera.target.length()
    };
  }

  //animation function to change intesnity of a light
  const changeLightIntensity = (object) => {
    //only run animation if no other light animations are currently running
    if (!tc.lightAnimationRunning) {
      tc.lightAnimationRunning = true;
      //get all lights that need to be triggered
      let lights = getAllChildrenByName(object.animationMesh, tc.scene);
      if (lights) {
        lights.forEach(light => {
          let timerInterval = object.secondBetweenAnimationChanges * 1000;

          //init delay
          setTimeout(function() {
            setAnimationDescriptionActive(true);
            //for each color change
            object.intensityChangeValues.forEach((intensity, i) => {
                //create custom timer and trigger light change accordingly
                setTimeout(function() {
                  setAnimationTitle("Current Lighting: ");
                  tweenLighting(light, getIntensityFromLightingPercentage(intensity), 800);
                  setAnimationDescription(intensity + " Brightness");
                }, (timerInterval * i));
            });

            //return to original light color
            setTimeout(function() {
              setAnimationTitle("Current Lighting: ");
              tweenLighting(light, getIntensityFromLightingPercentage(light.originalIntensity), 500);
              setAnimationDescription("Default Brightness");

              //allow animations again and remove animaiton description
              setTimeout(function() {
                tc.lightAnimationRunning = false;
                setAnimationDescriptionActive(false);
                tc.lastClickedMarker.children.map( markerPart => {
                    tweenOpacity(markerPart, 1.0, 500);
                });
              }, (timerInterval));

            }, (timerInterval * (object.intensityChangeValues.length)));
          }, (timerInterval));

        })
      }
    }
  }

  const changeLightColor = (object) => {
    //only run animation if no other light animations are currently running
    if (!tc.lightAnimationRunning) {
      tc.lightAnimationRunning = true;

      //get all lights that need to be triggered
      let lights = getAllChildrenByName(object.animationMesh, tc.scene);
      if (lights) {
        lights.forEach(light => {
          let timerInterval = object.secondBetweenAnimationChanges * 1000;

          //init delay
          setTimeout(function() {
            setAnimationDescriptionActive(true);
            //for each color change
            object.colorChangeValues.forEach((color, i) => {
                //create custom timer and trigger light change accordingly
                setTimeout(function() {
                  light.color.setHex(getColorFromLightingName(color));
                  setAnimationTitle("Current Lighting: ");
                  setAnimationDescription(color);
                }, (timerInterval * i));
            });

            //return to original light color
            setTimeout(function() {
              light.color.setHex(colorConvert(light.originalLightColor));
              tc.lightAnimationRunning = false;
              setAnimationDescriptionActive(false);
              tc.lastClickedMarker.children.map( markerPart => {
                  tweenOpacity(markerPart, 1.0, 500);
              });


            }, (timerInterval * (object.colorChangeValues.length)));
          }, (timerInterval));

        });
      }
    }
  }

  const getColorFromLightingName = (name) => {
    switch (name) {
      case 'Daylight':
        return '0x6aacd4';
      case 'Warm White':
        return '0xfedf88';
      case 'Bright White':
        return '0xFFFFFF';
      default:
        return '0xFFFFFF';
    }
  };

  const getIntensityFromLightingPercentage = (percentage) => {
    switch (percentage) {
      case '100%':
        return '2.0';
      case '50%':
        return '1.0';
      case '20%':
        return '0.5';
      case '10%':
        return '0.4';
      default:
        return '1.5';
    }
  };

  const filterMarkers = (filterType) => {
    setFilterHasBeenInitClicked(true);
    filterToast(filterType);
    tc.currentFilter = filterType;
    //if no filter type selected make all markers visible
    if (filterType === "none") {
      tc.scene.children.map(child => {
        if(child.name === "marker") {
          child.children.map( markerPart => {
            tweenOpacity(markerPart, 1.0, 500);
            tweenScale(markerPart, 1.0, 500);
          })
        }
      });
    } else {
      //determine what markers need to be filtered
      var markersToFilter = tc.markers.filter( markerObject => {
        return markerObject.markerType !== filterType;
      });
      var markerIDsToFilter = markersToFilter.map( markerObject => {
        return markerObject.markerID;
      });

      //filter markers in scene
      tc.scene.children.map(child => {
        if(child.name === "marker") {
          //hide marker if included in filter ID list. If not make it visible
          if (markerIDsToFilter.includes(child.id)) {
            child.children.map( markerPart => {
              tweenOpacity(markerPart, 0.4, 500);
              tweenScale(markerPart, 1.0, 500);
            })
          } else {
            child.children.map( markerPart => {
              tweenOpacity(markerPart, 1.0, 500);
              tweenScale(markerPart, 1.2, 500);
            })
          }
        }
      });
    }
  }

  //show toast when changing highlight
  const filterToast = (filterType) => {
    if(filterHasBeenInitClicked) {

      var markersToFilter = tc.markers.filter( markerObject => {
        return markerObject.markerType === filterType;
      });

      if (filterType === "none") {
        setAnimationDescriptionActive(false);
      } else {
        var markersToFilter = tc.markers.filter( markerObject => {
          return markerObject.markerType === filterType;
        });

        if (markersToFilter.length === 0) {
          var filter = "";
          switch (filterType) {
            case "tips":
              filter = "Tip";
              break;
            case "special offers":
              filter = "Special Offer";
              break;
            case "marketplace":
              filter = "Marketplace";
              break;
            case "smart home":
              filter = "Smart Home";
              break;
            default:
            break;
          }

          setAnimationDescription("No "+ filter + " Markers In This Room" );
        } else {

          var filter = "";
          switch (filterType) {
            case "tips":
              filter = "Tips";
              break;
            case "special offers":
              filter = "Special Offers";
              break;
            case "marketplace":
              filter = "Marketplace Markers";
              break;
            case "smart home":
              filter = "Smart Home Markers";
              break;
            default:
            break;
          }

          setAnimationDescription("Pan Around To See " +filter);
        }

        //beging to show toast for higlight
        setAnimationTitle("");
        toastCount = toastCount + 1;
        setAnimationDescriptionActive(true);

        //after timeout
        setTimeout(function() {
          if (toastCount <= 1) {
            setAnimationDescriptionActive(false);
          }
          toastCount = (toastCount - 1);
        }, 5000);

      }
    }
  }

  const tweenOpacity = (object, opacity, time) => {
    new TWEEN.Tween(object.material)
      .to({opacity: opacity}, time)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
  }

  const tweenScale = (object, scale, time) => {
    new TWEEN.Tween(object.scale)
      .to({x: scale , y: scale, z: scale}, time)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
  }

  const tweenLighting = (object, intensity, time) => {
    new TWEEN.Tween(object)
      .to({intensity: intensity}, time)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
  }

  const colorConvert = (colorString) => {
    return colorString.replace("#", "0x");
  }

  var highlightClasses = classNames({
    highlightRoom: true,
    // highlightActive
  });

  var animationDescriptionClasses = classNames({
    animationDescription: true,
    animationDescriptionActive
  });

  return (
    <div className="threeScene">
      <MetaTags>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </MetaTags>
      <div id="threeRoot" ref={mount} />
      <div className={highlightClasses}>
        <div className="highlightSolid">
          <CircularProgressbar
            styles={buildStyles({
              pathColor: '#ffffff',
            })}
            value={100}
            background={false}
            strokeWidth={2}
          >
          </CircularProgressbar>
        </div>
        <div className="highlightOuter">
          <CircularProgressbar
            styles={buildStyles({
              pathColor: '#ffffff',
            })}
            value={100}
            background={false}
            strokeWidth={12}
          >
          </CircularProgressbar>
        </div>
      </div>

      <MarkerModal />
      <CompletedModal />

      <div className={animationDescriptionClasses}>
        <div className="animationDescriptionContainer">
          <div className="animationDescriptionTitle">{animationTitle}</div>
          <div className="animationDescriptionContent">{animationDescription}</div>
        </div>
      </div>

      <div className="seoDescription">{seoDescription}</div>
    </div>
  );
}

export default ThreeRoom;
