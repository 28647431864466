const contentful = require("contentful");

let accessTokenValue, hostValue;

// CONTENTFUL_IS_PREVIEW will be a string value, not boolean
if(process.env.REACT_APP_CONTENTFUL_IS_PREVIEW === 'true') {
  accessTokenValue = process.env.REACT_APP_CONTENTFUL_PREVIEW_API;
  hostValue = 'preview.contentful.com'; // DO NOT ADD PROTOCOL
} else {
  accessTokenValue = process.env.REACT_APP_CONTENTFUL_DELIVERY_API;
  hostValue = 'cdn.contentful.com';
}

const cms = {
  setup: () => {
    if (typeof cms.cache === 'undefined') {
      cms.cache = {};
      cms.client = contentful.createClient({
        // This is the space ID. A space is like a project folder in Contentful terms
        space: "j777s7g7f9c7",
        // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
        accessToken: accessTokenValue,
        host: hostValue
      });
    }
  },

  getEntry: (id) => {
    if (!id) return new Promise(resolve => resolve(null));
    cms.setup();

    return new Promise((resolve, reject) => {
      if (cms.cache[id]) {
        resolve(cms.cache[id]);
        return;
      }

      cms.client.getEntry(id)
        .then(entry => {
          cms.cache[id] = entry;
          resolve(entry);
        })
        .catch(error => {
          console.error('cms.getEntry.loadCache', id, error);
          reject(error);
        });
    });
  },

  getEntries: (key) => {
    if (!key) return new Promise(resolve => resolve(null));
    cms.setup();

    return new Promise((resolve, reject) => {
      const strkey = JSON.stringify(key);
      if (cms.cache[strkey]) {
        resolve(cms.cache[strkey]);
        return;
      }

      cms.client.getEntries(key)
        .then(entry => {
          cms.cache[strkey] = entry;
          resolve(entry);
        })
        .catch(error => {
          console.error('cms.getEntries.loadCache', key, error);
          reject(error);
        });
    });
  },
}

export default cms;
