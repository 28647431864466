import React, { useState, Fragment, useEffect, useContext } from 'react';
import cms from '../../cmsservice';
import jsx from '../../jsxservice';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './RoomCard.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Why is there a repeat?
import Slider from "react-slick";
import SocialLinks from '../Common/SocialLinks';
import { constants } from '../Common/constants';
import { AppContext } from '../../store/context';
import showdown from "showdown";
import parse from 'html-react-parser';
import VideoThumbnail from '../Common/VideoThumbnail/VideoThumbnail';
var classNames = require("classnames");

function RoomCard({cardInfo, roomName}) {
  var converter = new showdown.Converter();
  const [cmsData, setCmsData] = useState({});
  const [sharelink, setSharelink] = useState('');
  const [cardIsShowing, setCardIsShowing] = useState(false);
  const [season, setSeason] = useState('cold');
  const { state } = useContext(AppContext);

  // Retrieve the seasonal data to set the correct season.
  // Only being used for videos at the time being.
  useEffect(() => {
    const getSeasonalData = async() => {
      const { fields: { hotSeasonStartDate, hotSeasonEndDate }} = await cms.getEntry('4gbEkpYveOL3YdTF0t7qpg');
      if(hotSeasonStartDate && hotSeasonEndDate) {
        const dates = [hotSeasonStartDate, hotSeasonEndDate].reduce((acc, itm) => {
          let d = new Date(itm);
          d.setFullYear(new Date().getFullYear());
          return [ ...acc, d.getTime() ];
        }, []);

        const now = new Date().getTime();
        if (dates[0] < now && dates[1] > now) setSeason('hot');
        else setSeason('cold');
      } else {
        setSeason('cold');
      }
    }

    getSeasonalData();
  }, []);

  useEffect(() => {
    if (!cardInfo) return;
    if ((cardInfo.markerType) && (cardInfo.markerType !== 'room') && cmsData.title) {
      setCardIsShowing(false);
    }
    cms.getEntry(cardInfo.entryID).then(data => {
      setTimeout(function () {
        if (cardInfo.markerType.toLowerCase() === 'room') setCmsData(buildRoom(data));
        else if (cardInfo.markerType.toLowerCase() === 'tips') setCmsData(buildTips(data));
        else setCmsData(buildStandard(data, cardInfo.markerType));
        const href = new URL(window.location.href);
        if (cardInfo.markerType !== 'room') href.search = 'marker=' + cardInfo.markerName;
        setSharelink(href.href);
        if ((cardInfo.markerType) && (cardInfo.markerType !== 'room') && cmsData.title) {
          setCardIsShowing(true);
        }
      }, 300);
    }).catch(error => console.error('RoomCard could not load entry', error));
  }, [cardInfo]); //eslint-disable-line react-hooks/exhaustive-deps

  // This will show which seasonal video to present for the card, if any.
  const buildVideo = (fields) => {
    // First, check if there any videos set for the room item.
    if(fields.videoWinter || fields.videoSummer) {
      
      // Check if the winter video is set for year-round
      // If videoSeasonal is non-existent from contentful, then it will count as year round as well.
      if(!fields.videoSeasonal) {
        // Year-Round
        if(fields.videoWinter) {
          return {
            ...fields.videoWinter.fields,
            thumbnail: fields.videoWinter.fields.thumbnail.fields.file.url
          }
        } else {
          return null;
        }
        
      } else {
        // Seasonal
        if(season === 'hot') {
          // Summer

          if(fields.videoSummer) {
            return {
              ...fields.videoSummer.fields,
              thumbnail: fields.videoSummer.fields.thumbnail.fields.file.url
            }
          } else {
            return null;
          }
        } else {
          // Winter
          
          if(fields.videoWinter) {
            return {
              ...fields.videoWinter.fields,
              thumbnail: fields.videoWinter.fields.thumbnail.fields.file.url
            }
          } else {
            return null;
          }
        }
      }
    } else {
      return null;
    }    
  }

  const buildRoom = ({ fields: { roomInfo: { fields = {} } = {} } = {} } = {}) => {
    return {
      title: fields.displayTitle || 'Loading...',
      sections: [{
        text: ((((((fields.bodyText || {}).content || [])[0] || {}).content || [])[0] || {}).value || null),
      }],
      savings: buildSavings(fields.savingDisplayed, fields.savingPrefix, fields.amountSaved, fields.savingUnitTracking, fields.savingPerTime),
    };
  }

  const buildTips = ({ fields = {} }) => {
    var ret = null
    if (fields.bodyText && fields.bodyTitle) {
      ret = {
        icon: {
          src: 'img/Icons/objectTypes/tips/tips.svg',
          color: '#80D8D3',
        },
        title: fields.displayTitle,
        overview: fields.overviewBody,
        tips: fields.tips.map(({ fields: tip }) => {
          return {
            title: tip.tipTitle,
            text: converter.makeHtml(tip.tipDescription),
          }
        }),
        sections: [{
          title: fields.bodyTitle,
          img: fields.images && fields.images
            .map(({ fields: { file: { url = null } = {} } = {} }) => url)
            .filter(url => url),
        }, ...buildSections(fields.bodyText)],
        savings: buildSavings(fields.savingDisplayed, fields.savingPrefix, fields.amountSaved, fields.savingUnitTracking, fields.savingPerTime),
      }
    } else {
      ret = {
        icon: {
          src: 'img/Icons/objectTypes/tips/tips.svg',
          color: '#80D8D3',
        },
        title: fields.displayTitle,
        overview: fields.overviewBody,
        tips: fields.tips.map(({ fields: tip }) => {
          return {
            title: tip.tipTitle,
            text: converter.makeHtml(tip.tipDescription),
          }
        }),
        savings: buildSavings(fields.savingDisplayed, fields.savingPrefix, fields.amountSaved, fields.savingUnitTracking, fields.savingPerTime),

      }
    }

    if (fields.ctaTitle && fields.ctaLink) {
      ret.learnmore = {
        text: fields.ctaTitle,
        link: fields.ctaLink,
      };
    }

    ret.video = buildVideo(fields);

    return ret;
  };

  const buildStandard = ({ fields = {} }, markerType) => {
     var ret = null;
     if (fields.bodyText && fields.bodyTitle) {
       ret = {
         title: fields.displayTitle,
         overview: fields.overviewBody,
         sections: [{
           title: fields.bodyTitle,
           img: fields.images && fields.images
             .map(({ fields: { file: { url = null } = {} } = {} }) => url)
             .filter(url => url),
         }, ...buildSections(fields.bodyText)],
         savings: buildSavings(fields.savingDisplayed, fields.savingPrefix, fields.amountSaved, fields.savingUnitTracking, fields.savingPerTime),
       };
     } else {
       ret = {
         title: fields.displayTitle,
         overview: fields.overviewBody,
         sections: [{
           title: fields.bodyTitle,
           img: fields.images && fields.images
             .map(({ fields: { file: { url = null } = {} } = {} }) => url)
             .filter(url => url),
         }],
         savings: buildSavings(fields.savingDisplayed, fields.savingPrefix, fields.amountSaved, fields.savingUnitTracking, fields.savingPerTime),
       };
     }

    switch(markerType) {
      case 'marketplace':
        ret.icon = {
          src: 'img/Icons/objectTypes/marketplace/marketplace.svg',
          color: '#39AAC8',
        };
        break;
      case 'special offer':
      case 'special offers':
        ret.icon = {
          src: 'img/Icons/objectTypes/specialOffers/specialOffers.svg',
          color: '#2E9CFF',
        };
        break;
      case 'smart home':
        ret.icon = {
          src: 'img/Icons/objectTypes/smartHome/smartHome.svg',
          color: '#103DBC',
        };
        break;
      default:
    }
    if (fields.ctaTitle && fields.ctaLink) {
      ret.learnmore = {
        text: fields.ctaTitle,
        link: fields.ctaLink,
      };
    }

    ret.video = buildVideo(fields);

    return ret;
  }

  const checkFormat = (amount) => {
    if (cmsData.savings.units === "dollars / year" || cmsData.savings.units === "dollars / month") {
      return ("$"+amount);
    } else {
      return amount;
    }
  }

  const buildSavings = (displayed, prefix, amountSaved, unitTracking, perTime) => {
    if (!displayed) return;
    if (!amountSaved) return;
    if (!unitTracking) return;
    if (!perTime) return;
    let units = '';
    if (unitTracking) units += amountCased(unitTracking);
    if (perTime) units += ' / ' + amountCased(perTime);
    return {
      prefix, // Optional
      amountSaved,
      units,
    };
  }

  const buildSections = ({ content }) => {
    return content.map(node => {
      return {
        jsx: jsx.ify(node),
      }
    });
  }

  const learnmoreClicked = () => {
    constants.gmt({
      state,
      dataTrackDetail: cmsData.learnmore.text,
      dataTrackSubdetail: cmsData.title,
      dataTrackAction: constants.analytics.click,
      dataTrackSubaction: roomName.displayName,
    });
  };

  const addTargetBlank = (html) => {
    if (html) {
      return html.replace("<a href", "<a target='_blank' href");
    } else {
      return html;
    }
  }

  var cardClasses = classNames({
    cardNav: true,
    showCard: cardIsShowing
  });

  const amountCased = (value) => {
    if (value === "Year") {
      return "year";
    }

    if (value === "Month") {
      return "month";
    }
    if(value === "Dollars") {
      return "dollars";
    }

    if (value === "dollars / year") {
      return ("per year");
    }

    if (value === "dollars / month") {
      return ("per month");
    }

    return value;

  }

  return (
    <div className={cardClasses}>
      <div className="RoomCard">
        <div className="roomCardInside">
          <div className="titleContainer">
            { cmsData.icon &&
              <div className="circle" style={{backgroundColor: cmsData.icon.color}}>
                <img src={cmsData.icon.src} alt={cardInfo.markerType}/>
              </div>
            }
            <div className="titleText">{cmsData.title}</div>
          </div>
          { cmsData.savings &&
            <Fragment>
              <div className="savingContainer">
                <div className="savings">
                  <div className="savingSubtitle">Save</div>
                  <div className="savingPrefix">{cmsData.savings.prefix}</div>
                  <div className="saveamount">{checkFormat(Math.round(cmsData.savings.amountSaved))}</div>
                  <div className="saveunits">{amountCased(cmsData.savings.units)}</div>
                </div>
              </div>
            </Fragment>
          }
          <div className="bodyContent">
            {cmsData.overview &&
              <div className="overviewContainer">
                <div className="overviewTitle">Overview</div>
              <div className="text">{parse(addTargetBlank(converter.makeHtml(cmsData.overview)))}</div>
              </div>
            }
            {
              cmsData.video &&
              <Fragment>
                <VideoThumbnail video={ cmsData.video } />
              </Fragment>
            }
            { cmsData.tips &&
              <Fragment>
                <Slider slidesToShow={1} slidesToScroll={1} dots={true} infinite={true} speed={500} arrows={false}>
                  { cmsData.tips.map((tip, idx) => (
                      <div index={idx} key={idx}>
                        <div className="tipcard">
                          <div className="tipContentContainer">
                            {tip.title && <div className="tiptitle">{tip.title}</div>}
                            {tip.text && <div className="tiptext">{parse(addTargetBlank(converter.makeHtml(tip.text)))}</div>}
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </Fragment>
            }
            { cmsData.sections && cmsData.sections.map((sec, idx) => (
              <Fragment key={idx}>
                  {sec.img && sec.img.length === 1 && <img src={sec.img} alt={sec.title} className="secImg"/>}
                  {(sec.img && sec.img.length > 1) &&
                    <div className="carousel">
                      <Slider slidesToShow={1} slidesToScroll={1} dots={true} infinite={true} arrows={false}>
                        { sec.img.map((img, idx) => (
                            <div index={idx} key={idx} className="imgContainer">
                              <img src={img} alt={cmsData.title + ' Image'}/>
                            </div>
                          ))}
                      </Slider>
                    </div>
                  }
                  {sec.title && <div className="bodyTextTitle">{sec.title}</div>}
                  {sec.jsx || sec.text &&
                    <div>
                      {parse(addTargetBlank(converter.makeHtml(sec.jsx)))}
                      <div className="bodyText">{parse(addTargetBlank(converter.makeHtml(sec.text)))}</div>
                    </div>
                  }
              </Fragment>
            ))}
          </div>
          <div className="cardFixedBottom">
            { cmsData.learnmore &&
              <div className="CTAContainer" onClick={learnmoreClicked}>
                <a href={cmsData.learnmore.link} className="learnhref" target="_blank" rel="noopener noreferrer">
                  <div className="learnmore">{cmsData.learnmore.text}</div>
                </a>
              </div>
            }
            <div className="sharing">
              <SocialLinks {...{sharelink}} />
            </div>
          </div>
          { cmsData.savings &&
            <div className="disclosureText">
              Savings values are derived from the Michigan Energy Measures Database and DTE program evaluations. All savings are approximate.
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default RoomCard;
