import React, { useContext, useEffect } from "react";
import DteModal from "../../Common/DteModal/DteModal";
import { AppContext } from "../../../store/context";
import { ActionType } from "../../../store/actions";
import "./VideoModal.css";

const VideoModal = () => {
    const { state, dispatch } = useContext(AppContext);

    const toggleVideoModal = () => {
        dispatch({ type: ActionType.TOGGLE_VIDEO_MODAL });
    };

    const modalStyle = {
        content: {
            width: "100vw",
            height: "95vh",
            bottom: "0",
            left: "0",
            right: "0",
            top: "0",
            margin: "auto",
            display: "flex",
            position: "relative",
          },
          overlay: {
            backgroundColor: "rgba(30, 53, 117, 0.2)",
            zIndex: "9999"
          },
    }

    if(state.video) {
        return (
            <DteModal modalStyle={ modalStyle }
                isOpen={ state.isVideoModalOpen }
                onClose={ toggleVideoModal }>
                    <header className="videoModalTitle">
                        <h2>{ state.video.title }</h2>
                    </header>
                    <div className="videoModalVideo">
                        <iframe width="560" height="349" src={ `${state.video.embeddedUrl}?modestbranding=1&controls=1&rel=0&autoplay=1` } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    
            </DteModal>
        );
    } else {
        return null;
    }
};

export default VideoModal;