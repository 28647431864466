export const constants = {
  analytics: {
    click: "click",
    exploreroom: "explore the room",
    highlight: "highlight selection",
    home: "home buttons",
    houseactivity: "houseActivity",
    markerselected: "room element selected",
    myprogress: "my progress",
    nextroom: "next room selection",
    social: "social share",
    startexploring: "start exploring",
  },
  markerType: {
    tips: "tips",
    marketplace: "marketplace",
    specialOffer: "special offers",
    smartHome: "smart home",
  },
  ringType: {
    modal: 1,
    side: 2,
  },
  ringTotals: {
    tips: 20,
    marketplace: 4,
    "special offers": 9,
    "smart home": 7,
  },
  progressTotal: {
    treesPlanted: 101.74,
    bagsOfWasteRecycled: 263,
    gallonsOfGasSaved: 694.64,
  },
  roomMap: [
    {
      id: -1,
      name: "default",
      numberOfMarkersHot: 10,
      numberOfMarkersCold: 10,
    },
    { id: 1, name: "living", numberOfMarkersHot: 6, numberOfMarkersCold: 6 },
    { id: 2, name: "kitchen", numberOfMarkersHot: 3, numberOfMarkersCold: 3 },
    { id: 3, name: "child", numberOfMarkersHot: 4, numberOfMarkersCold: 4 },
    { id: 3, name: "bedroom", numberOfMarkersHot: 4, numberOfMarkersCold: 4 },
    {
      id: 4,
      name: "entertainment",
      numberOfMarkersHot: 3,
      numberOfMarkersCold: 3,
    },
    { id: 4, name: "bonus", numberOfMarkersHot: 3, numberOfMarkersCold: 3 },
    { id: 5, name: "bathroom", numberOfMarkersHot: 3, numberOfMarkersCold: 3 },
    { id: 6, name: "basement", numberOfMarkersHot: 6, numberOfMarkersCold: 6 },
    { id: 7, name: "office", numberOfMarkersHot: 4, numberOfMarkersCold: 4 },
    { id: 8, name: "garage", numberOfMarkersHot: 3, numberOfMarkersCold: 3 },
    { id: 9, name: "attic", numberOfMarkersHot: 4, numberOfMarkersCold: 4 },
    { id: 9, name: "roof", numberOfMarkersHot: 4, numberOfMarkersCold: 4 },
    { id: 10, name: "exterior", numberOfMarkersHot: 4, numberOfMarkersCold: 3 },
  ],
  gmt: ({
    state: { visitedMarkers },
    dataTrackDetail,
    dataTrackSubdetail,
    dataTrackAction,
    dataTrackSubaction,
  }) => {
    if (!constants.totalMarkerCount || constants.totalMarkerCount < 1) {
      constants.totalMarkerCount =
        Object.keys(constants.ringTotals)
          .map((key) => constants.ringTotals[key])
          .reduce((acc, curr) => acc + curr, 0) || 1;
    }

    const calc = (type) => {
      return (
        visitedMarkers.filter((vm) => vm.markerType === type).length /
        constants.ringTotals[type]
      );
    };

    (window.dataLayer || []).push({
      event: constants.analytics.houseactivity,
      ovrCompletion: visitedMarkers.length / constants.totalMarkerCount,
      ovrTipsCompletion: calc(constants.markerType.tips),
      ovrMarketplaceCompletion: calc(constants.markerType.marketplace),
      ovrOffersCompletion: calc(constants.markerType.specialOffer),
      ovrSmarthomeCompletion: calc(constants.markerType.smartHome),
      dataTrackDetail,
      dataTrackSubdetail,
      dataTrackAction,
      dataTrackSubaction,
    });
  },
};
