import React, { useContext } from 'react';
import './VideoThumbnail.css';
import { AppContext } from "../../../store/context";
import { ActionType } from "../../../store/actions";

const VideoThumbnail = (props) => {
    const { state, dispatch } = useContext(AppContext);

    const showVideoModal = () => {
        dispatch({ type: ActionType.TOGGLE_VIDEO_MODAL });
        dispatch({ type: ActionType.SET_VIDEO_DATA, payload: props.video  })
    }

    if(props.video) {
        return (
            <div className="video" onClick={ showVideoModal }>
                <div className="videoThumbnail">
                    <img src={ props.video.thumbnail } alt={ props.video.thumbnailAlt } />
                </div>
    
                <div className="videoTitle">
                    <p>Watch:</p>
                    <p>{ props.video.title }</p>
                </div>
            </div>
        );
    } else {
        return null;
    }
    
};

export default VideoThumbnail;