import { constants } from "./components/Common/constants";

export default function ProgressRingsService(state, ringStyle) {
  const getVisitedFor = (type) => {
    return state.visitedMarkers.filter((x) => x.markerType == type).length;
  };

  const isComplete = (type) => {
    return constants.ringTotals[type] == getVisitedFor(type);
  };

  var ProgressRings = [
    {
      type: constants.markerType.tips,
      value: (getVisitedFor(constants.markerType.tips) / constants.ringTotals[constants.markerType.tips]) * 100,
      title: "TIPS",
      description: isComplete(constants.markerType.tips)
        ? "You've seen all the tips!"
        : "Find all the tips.",
      currentProgress: {
        visited: getVisitedFor(constants.markerType.tips),
        total: constants.ringTotals[constants.markerType.tips],
      },
      imgSrc: "img/Icons/objectTypes/tips/tips.svg",
      isComplete: isComplete(constants.markerType.tips),
      ringStyle: ringStyle
    },
    {
      type: constants.markerType.marketplace,
      value: (getVisitedFor(constants.markerType.marketplace) / constants.ringTotals[constants.markerType.marketplace]) * 100,
      title: "MARKETPLACE",
      description: isComplete(constants.markerType.marketplace)
        ? "You've seen all the marketplace offerings!"
        : "See all marketplace offerings.",
      currentProgress: {
        visited: getVisitedFor(constants.markerType.marketplace),
        total: constants.ringTotals[constants.markerType.marketplace],
      },
      imgSrc: "img/Icons/objectTypes/marketplace/marketplace.svg",
      isComplete: isComplete(constants.markerType.marketplace),
      ringStyle: ringStyle
    },
    {
      type: constants.markerType.specialOffer,
      value: (getVisitedFor(constants.markerType.specialOffer) / constants.ringTotals[constants.markerType.specialOffer]) * 100,
      title: "SPECIAL OFFERS",
      description: isComplete(constants.markerType.specialOffer)
        ? "You've seen all the special offers!"
        : "Find all the special offers.",
      currentProgress: {
        visited: getVisitedFor(constants.markerType.specialOffer),
        total: constants.ringTotals[constants.markerType.specialOffer],
      },
      imgSrc: "img/Icons/objectTypes/specialOffers/specialOffers.svg",
      isComplete: isComplete(constants.markerType.specialOffer),
      ringStyle: ringStyle
    },
    {
      type: constants.markerType.smartHome,
      value: (getVisitedFor(constants.markerType.smartHome) / constants.ringTotals[constants.markerType.smartHome]) * 100,
      title: "SMART HOME",
      description: isComplete(constants.markerType.smartHome)
        ? "You've seen all the smart home items!"
        : "Find all smart home items.",
      currentProgress: {
        visited: getVisitedFor(constants.markerType.smartHome),
        total: constants.ringTotals[constants.markerType.smartHome],
      },
      imgSrc: "img/Icons/objectTypes/smartHome/smartHome.svg",
      isComplete: isComplete(constants.markerType.smartHome),
      ringStyle: ringStyle
    },
  ];

  return ProgressRings;
}
