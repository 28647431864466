export const ActionType = {
  TOGGLE_PROGRESS_MODAL: "TOGGLE_PROGRESS_MODAL",
  TOGGLE_VIDEO_MODAL: "TOGGLE_VIDEO_MODAL",
  SET_VIDEO_DATA: "SET_VIDEO_DATA",
  UPDATE_VISTED_MARKERS: "UPDATE_VISTED_MARKERS",
  SET_ROOM_AS_VISITED: "SET_ROOM_AS_VISITED",
  DISMISS_MARKER_MODAL: "DISMISS_MARKER_MODAL",
  SHOW_COMPLETED_MODAL: "SHOW_COMPLETED_MODAL",
  DISMISS_COMPLETED_MODAL: "DISMISS_COMPLETED_MODAL",
  SET_CURRENT_ROOM_ID: "SET_CURRENT_ROOM_ID",
  TOGGLE_SIDE_NAV_EXPANDED: "TOGGLE_SIDE_NAV_EXPANDED",
  SHOW_SIDE_NAV: "SHOW_SIDE_NAV",
  HIDE_SIDE_NAV: "HIDE_SIDE_NAV",
  SET_MARKER_FILTER_TYPE: "SET_MARKER_FILTER_TYPE",
  SET_LOADING: "SET_LOADING",
  UPDATE_MAP_PAGE_CHANGE: "UPDATE_MAP_PAGE_CHANGE",
};
