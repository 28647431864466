import React, { useContext, useEffect } from "react";
import DteModal from "../../Common/DteModal/DteModal";
import { AppContext } from "../../../store/context";
import { ActionType } from "../../../store/actions";
import "./ProgressModal.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { constants } from "../../Common/constants";
import ProgressRingsService from "../../../ProgressRingsService";
import ProgressRing from "../../Common/ProgressRing";
import map from "../../../roommap";

function ProgressModal() {
  const { state, dispatch } = useContext(AppContext);

  const toggleProgressModal = () => {
    dispatch({ type: ActionType.TOGGLE_PROGRESS_MODAL });
  };

  useEffect(() => {
    if (state.isProgressModalOpen) {
      constants.gmt({
        state,
        dataTrackDetail: constants.analytics.myprogress,
        dataTrackAction: constants.roomMap.filter(r => r.id === state.currentRoomId).map(r => map(r.name).displayName)[0] || 'Unknown',
        dataTrackSubdetail: constants.analytics.click,
      });
    }
  }, [state.isProgressModalOpen]);

  const getSavingsStyles = () => {
    return {
      root: {},
      path: {
        stroke: "#0072CE",
        strokeLinecap: "round",
        transition: "stroke-dashoffset 0.5s ease 0s",
        transform: "rotate(0.7turn)",
        transformOrigin: "center center",
        strokeWidth: "3px",
      },
      trail: {
        stroke: "#d6d6d6",
        strokeLinecap: "round",
        transform: "rotate(0.7turn)",
        transformOrigin: "center center",
        strokeWidth: "2px",
      },
    };
  };

  const getVisitedFor = (type) => {
    return state.visitedMarkers.filter((x) => x.markerType == type).length;
  };

  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const calculateSavingsValue = (type) => {
    switch (type) {
      case "treesPlanted":
        return Math.round(state.treesPlanted);
        break;
      case "bagsOfWasteRecycled":
        return Math.round(state.bagsOfWasteRecycled);
        break;
      case "gallonsOfGasSaved":
        return Math.round(state.gallonsOfGasSaved);
        break;
    }
  };

  const getMobileModalStyle = () => {
    return {
      content: {
        width: "70%",
        height: "80%",
        top: "5%",
        left: "auto",
        right: "auto",
        position: "relative",
        margin: "0 auto",
      },
      overlay: {
        backgroundColor: "rgba(30, 53, 117, 0.2)",
        zIndex: "9999"
      },
    };
  }

  var savingsRings = [
    {
      value: (calculateSavingsValue("treesPlanted") / constants.progressTotal.treesPlanted) * 100,
      title: "TREES PLANTED",
      imgSrc: "img/Icons/trees.svg",
      numberValue: numberWithCommas(calculateSavingsValue("treesPlanted")),
    },
    {
      value: (calculateSavingsValue("bagsOfWasteRecycled") / constants.progressTotal.bagsOfWasteRecycled) * 100,
      title: "BAGS OF WASTE RECYCLED",
      imgSrc: "img/Icons/waste.svg",
      numberValue: numberWithCommas(calculateSavingsValue("bagsOfWasteRecycled")),
    },
    {
      value: (calculateSavingsValue("gallonsOfGasSaved") / constants.progressTotal.gallonsOfGasSaved) * 100,
      title: "GALLONS OF GAS SAVED",
      imgSrc: "img/Icons/gallons.svg",
      numberValue: numberWithCommas(calculateSavingsValue("gallonsOfGasSaved")),
    },
  ];

  return (
    <DteModal isOpen={state.isProgressModalOpen} onClose={toggleProgressModal} modalStyle={window.innerWidth <= 1008 ? getMobileModalStyle() : null}>
      <div className={window.innerWidth <= 1008 ? "myProgressMobile" : "myProgress"}>My Progress</div>
      <div className="row">
        {ProgressRingsService(state, constants.ringType.modal).map((progressRing, index) => (
          <div key={index} className={window.innerWidth <= 1008 ? "progressRingMobile" : "progressRing"}>
            <ProgressRing progressRing={progressRing} />
            <div className="titles"><span className="overflowCenter">{progressRing.title}</span></div>
            <div className="description">{progressRing.description}</div>
          </div>
        ))}
      </div>
      <div className="progressHorizontalLine" />
      <div className="savingsTitle">Savings Equal To</div>
      <div className="row">
        {savingsRings.map((savingsRing, index) => (
          <div key={index} className={window.innerWidth <= 1008 ? "savingsRingMobile" : "savingsRing"}>
            <CircularProgressbarWithChildren
              value={savingsRing.value}
              circleRatio={0.6}
              styles={getSavingsStyles()}
              strokeWidth={3.5}
            >
              <img
                style={{ width: 50, marginTop: -30 }}
                src={savingsRing.imgSrc}
              />
              <div className="savingsNumbers">{savingsRing.numberValue}</div>
            </CircularProgressbarWithChildren>
            <div className="savingsSubTitle"><span className="overflowCenter">{savingsRing.title}</span></div>
          </div>
        ))}
      </div>
      <div class="disclosureText">
        Calculations regarding the savings of trees planted, bags of waste recycled, and gallons of gas saved are based on the <a href="https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator">Greenhouse Gas Equivalencies Calculator</a> provided by the Environmental Protection Agency. All estimates are approximate.
      </div>
    </DteModal>
  );
}

export default ProgressModal;
