import React, { useContext } from "react";
import "./MarkerModal.css";
import DteModal from "../../Common/DteModal/DteModal";
import { AppContext } from "../../../store/context";
import { ActionType } from "../../../store/actions";
import ProgressRingsService from "../../../ProgressRingsService";
import ProgressRing from "../../Common/ProgressRing";
import { constants } from "../../Common/constants";

function MarkerModal() {
  const { state, dispatch } = useContext(AppContext);

  var progressRing = ProgressRingsService(state, constants.ringType.modal).find(
    (x) => x.type == state.firstMarkerType
  );

  const dismissMarkerModal = () => {
    dispatch({ type: ActionType.DISMISS_MARKER_MODAL });
  };

  const seeProgressClick = () => {
    dispatch({ type: ActionType.DISMISS_MARKER_MODAL });
    dispatch({ type: ActionType.TOGGLE_PROGRESS_MODAL })
  }

  return (
    <DteModal
      isOpen={state.hasClickedMarker && !state.hasDismissedMarkerModal}
      onClose={dismissMarkerModal}
      width={"450px"}
      height={"auto"}
    >
      <div className="markerModalContent">
        <div>
          {progressRing ? (
            <div className="ring">
              <ProgressRing progressRing={progressRing} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="modalTitle">You've Just Earned Progress!</div>
        <div className="markerSubTitle">
        Congratulations! You’ve earned your first badge! Continue exploring our virtual home to discover even more energy saving methods you can use in your home!
        </div>
        <div className="modalButton" onClick={seeProgressClick}>
          See My Progress
        </div>
      </div>
    </DteModal>
  );
}

export default MarkerModal;
