import React, { Fragment, useContext } from 'react';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import "./SocialLinks.css";
import { constants } from '../Common/constants';
import { AppContext } from '../../store/context';
import map from '../../roommap';

const SocialLinks = ({sharelink}) => {
  const { state } = useContext(AppContext);

  const buttonClicked = (which) => {
    const url = new URL(sharelink);
    constants.gmt({
      state,
      dataTrackDetail: constants.analytics.social,
      dataTrackSubdetail: which,
      dataTrackAction: constants.analytics.click,
      dataTrackSubaction: map(url.pathname.substring(1)).displayName,
    });
  };
  const emailURL = "mailto:?subject=DTE Smart Home Link&body=Check out what I found in DTE's Smart Home "+ sharelink+".";
  return (
    <Fragment>
      <FacebookShareButton url={sharelink} className="socialLink" quote="Check out what I found in DTE's Smart Home" onClick={() => buttonClicked('facebook')}>
        <img src={process.env.PUBLIC_URL + 'img/Icons/social/facebook.svg'} className="socialIcon" alt="Share on Facebook"/>
      </FacebookShareButton>
      <TwitterShareButton url={sharelink} className="socialLink" title="Check out what I found in DTE's Smart Home" hashtags={["DTE", "SmartHome"]} onClick={() => buttonClicked('twitter')}>
        <img src={process.env.PUBLIC_URL + 'img/Icons/social/twitter.svg'} className="socialIcon" alt="Share on Twitter"/>
      </TwitterShareButton>
      <a className="socialLink" href={emailURL}
         title="Share by Email">
        <img src={process.env.PUBLIC_URL + 'img/Icons/social/email.svg'} className="socialIcon" alt="Share by eMail"/>
      </a>

    </Fragment>
  );
}

export default SocialLinks;
