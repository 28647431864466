import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./MapPage.css";
import cms from '../../cmsservice';
import Lottie from "react-lottie";
import mapHouse from "./lottieHouse/map2021.json";
import houseLoading from "./lottieHouse/houseloading.json";
import mapHouseCold from "./lottieHouse/map-snow.json";
import houseLoadingCold from "./lottieHouse/houseloadingsnow.json";
import { useSpring, animated } from "react-spring";
import { AppContext } from "../../store/context";
import { ActionType } from "../../store/actions";
import { constants } from "../Common/constants";
import SocialLinks from "../Common/SocialLinks";
import MetaTags from 'react-meta-tags';
import VideoThumbail from '../Common/VideoThumbnail/VideoThumbnail';
var classNames = require("classnames");
const { detect } = require('detect-browser');
const browser = detect();

const mobileBreakpoint = 1008;

//elements that will be set up as trigger hotspots in lottie animation
const lottieRoomIDs = [
  {
    id:"living",
    displayName: "Living Room",
    hitboxID: "hitbox_living",
    highlightID: "highlight_living",
    floorID: 'floor_floor1',
    floor: "floor1",
    markerID: "marker_living",
    url: "/living",
    roomId: 1,
  },
  {
    id:"kitchen",
    displayName: "Kitchen",
    hitboxID: "hitbox_kitchen",
    highlightID: "highlight_kitchen",
    floorID: 'floor_floor1',
    floor: "floor1",
    markerID: "marker_kitchen",
    url: "/kitchen",
    roomId: 2,
  },
  {
    id:"child",
    displayName: "Childs Bedroom",
    hitboxID: "hitbox_child",
    highlightID: "highlight_child",
    floorID: 'floor_floor2',
    floor: "floor2",
    markerID: "marker_child",
    url: "/child",
    roomId: 3,
  },
  {
    id:"entertainment",
    displayName: "Entertainment",
    hitboxID: "hitbox_entertainment",
    highlightID: "highlight_entertainment",
    floorID: 'floor_floor2',
    floor: "floor2",
    markerID: "marker_entertainment",
    url: "/entertainment",
    roomId: 4,
  },
  {
    id:"bathroom",
    displayName: "Bathroom",
    hitboxID: "hitbox_bathroom",
    highlightID: "highlight_bathroom",
    floorID: 'floor_floor2',
    floor: "floor2",
    markerID: "marker_bathroom",
    url: "/bathroom",
    roomId: 5,
  },
  {
    id:"basement",
    displayName: "Basement",
    hitboxID: "hitbox_basement",
    highlightID: "highlight_basement",
    floorID: 'floor_basement',
    floor: "basement",
    markerID: "marker_basement",
    url: "/basement",
    roomId: 6,
  },
  {
    id:"office",
    displayName: "Office",
    hitboxID: "hitbox_office",
    highlightID: "highlight_office",
    floorID: 'floor_floor1',
    floor: "floor1",
    markerID: "marker_office",
    url: "/office",
    roomId: 7,
  },
  {
    id:"garage",
    displayName: "Garage",
    hitboxID: "hitbox_garage",
    highlightID: "highlight_garage",
    floorID: 'floor_floor1',
    floor: "floor1",
    markerID: "marker_garage",
    url: "/garage",
    roomId: 8,
  },
  {
    id:"attic",
    displayName: "Attic",
    hitboxID: "hitbox_attic",
    highlightID: "highlight_attic",
    floorID: 'floor_attic',
    floor: "attic",
    markerID: "marker_attic",
    url: "/attic",
    roomId: 9,
  },
  {
    id:"exterior",
    displayName: "Exterior",
    hitboxID: "hitbox_exterior",
    highlightID: "highlight_exterior",
    floorID: 'floor_floor1',
    floor: "floor1",
    markerID: "marker_exterior",
    url: "/exterior",
    roomId: 10,
  }
];

//interactions for items effected by lottie hotspot hover
const atticInteraction = {
  attic: "none",
  floor2: "up",
  floor1: "up",
  basement: "up"
};

const floor2Interaction = {
  attic: "down",
  floor2: "none",
  floor1: "up",
  basement: "up"
};

const floor1Interaction = {
  attic: "down",
  floor2: "down",
  floor1: "none",
  basement: "up"
};

const basementInteraction = {
  attic: "down",
  floor2: "down",
  floor1: "down",
  basement: "none"
};

//items effected by hotspot hover
var allMarkers = document.getElementsByClassName("marker");
const lottieElements = [
  {
    id: "floor_attic",
    interaction: atticInteraction
  },
  {
    id: "floor_floor2",
    interaction: floor2Interaction
  },
  {
    id: "floor_floor1",
    interaction: floor1Interaction
  },
  {
    id: "floor_basement",
    interaction: basementInteraction
  },
  {
    id: "shadow_attic",
    interaction: atticInteraction
  },
  {
    id: "shadow_floor2",
    interaction: floor2Interaction
  },
  {
    id: "shadow_floor1",
    interaction: floor1Interaction
  },
  {
    id: "shadow_basement",
    interaction: basementInteraction
  },
  {
    id: "living",
    interaction: floor1Interaction
  },
  {
    id: "kitchen",
    interaction: floor1Interaction
  },
  {
    id: "child",
    interaction: floor2Interaction
  },
  {
    id: "entertainment",
    interaction: floor2Interaction
  },
  {
    id: "bathroom",
    interaction: floor2Interaction
  },
  {
    id: "basement",
    interaction: basementInteraction
  },
  {
    id: "office",
    interaction: floor1Interaction
  },
  {
    id: "garage",
    interaction: floor1Interaction
  },
  {
    id: "attic",
    interaction: atticInteraction
  },
  {
    id: "exterior",
    interaction: floor1Interaction
  },
  {
    id: "atticlabel",
    interaction: atticInteraction
  },
  {
    id: "floor2",
    interaction: floor2Interaction
  },
  {
    id: "floor1",
    interaction: floor1Interaction
  },
  {
    id: "basementlabel",
    interaction: basementInteraction
  }
]

const houseLoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: houseLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const houseLoadingColdOptions = {
  loop: false,
  autoplay: true,
  animationData: houseLoadingCold,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const mapHouseOptions = {
  loop: false,
  autoplay: true,
  animationData: mapHouse,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const mapHouseOptionsCold = {
  loop: false,
  autoplay: true,
  animationData: mapHouseCold,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function MapPage() {
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const [mobileFooterSpring, setMobileFooterSpring] = useSpring(() => ({
    bottom: -124
  }));
  const [overlaySpring, setOverlaySpring] = useSpring(() => ({opacity: 3}));
  const [isStopped, setIsStopped] = useState(false);
  const [mapIsDisplayed, setMapIsDisplayed] = useState(false);
  const [mapAnimationFinished, setMapAnimationFinished] = useState(false);
  const [completeLoopOpen, setCompleteLoopOpen] = useState(true);
  const [lottieSeperateActive, setLottieSeperateActive] = useState(false);
  const [roomHoverStyle, setRoomHoverStyle] = useState();
  const [isRoomHovered, setIsRoomHovered] = useState(false);
  const [currentRoomHover, setCurrentRoomHover] = useState();
  const [mobileRoomSelected, setMobileRoomSelected] = useState(lottieRoomIDs[0])
  const [season, setSeason] = useState(null)
  const [sharelink, setSharelink] = useState("");
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const [videoCard, setVideoCard] = useState(null);

  let isLottieComplete = false;

  //get season
  cms.getEntry('4gbEkpYveOL3YdTF0t7qpg').then(({ fields: { hotSeasonStartDate, hotSeasonEndDate }}) => {
    if (hotSeasonStartDate && hotSeasonEndDate) {
      const dates = [hotSeasonStartDate, hotSeasonEndDate].reduce((acc, itm) => {
        let d = new Date(itm);
        d.setFullYear(new Date().getFullYear());
        return [ ...acc, d.getTime() ];
      }, []);
      const now = new Date().getTime();
      if (dates[0] < now && dates[1] > now) setSeason('hot');
      else setSeason('cold');
    }
    else {
      setSeason('cold');
    }
  });

  useEffect(() => {
    // get the video card information
    cms.getEntry('3B9mXC7aGbXW7iVxkYEg9c').then((data) => {
      setVideoCard({
        ...data.fields,
        thumbnail: data.fields.thumbnail.fields.file.url
      })
    });
  }, []);

  setOverlaySpring({opacity: 0});

  useEffect(() => {
    const url = new URL(window.location.href);
    setSharelink(url.origin + url.pathname);
  }, []);

  useEffect(() => {
    if (state.roomHasBeenVisited === true) {
        state.mapPageChange = "HOME";
    }
    let isNowOnMapPage = state.mapPageChange === "HOME";
    if (isNowOnMapPage) {
      startExploreHome();
    }
  }, [state.mapPageChange]);

  useEffect(() => {
    filterMapMarkersByType(state.filterType);
  }, [state.filterType]);

  useEffect(() => {
    // set up side nav visibility on mobile on map page
    if (window.innerWidth <= mobileBreakpoint && state.isSideNavExpanded) {
      dispatch({ type: ActionType.TOGGLE_SIDE_NAV_EXPANDED });
    }
  }, []);

  const startExploreHome = () => {
    setMapIsDisplayed(true);

    constants.gmt({
      state,
      dataTrackDetail: constants.analytics.startexploring,
      dataTrackAction: constants.analytics.click,
    });
  };

  const lottieInteractionInit = () => {
    setMapAnimationFinished(true);
    setCompleteLoopOpen(false);

    //desktop only lottie hover/click interaction
    if (window.innerWidth >= mobileBreakpoint) {
      //browser specific lottie patch
      switch (browser && browser.name) {
        case 'chrome':
        case 'edge':
        case 'edge-chromium':
          addInteractionClassToLottieLayers();
          break;
        default:
          break;
      }

      //get room highlight ui width for positioning later
      var roomHighlightUIBounding = document.getElementById("roomHighlightUI").getBoundingClientRect();
      var roomHighlightUIWidth = roomHighlightUIBounding.width;

      lottieRoomIDs.map((room) => {
        var roomLottieObject = document.getElementById(room.hitboxID);
        var highlight = document.getElementById(room.highlightID);
        if (!roomLottieObject) {
          return;
        }
        roomLottieObject.highlight = highlight;

        roomLottieObject.addEventListener("click", function () {
          document.body.style.cursor = "default";
          history.push(room.url);
          dispatch({ type: ActionType.UPDATE_MAP_PAGE_CHANGE, payload: room });
        });

        roomLottieObject.addEventListener("mouseover", function (e) {
          const highlightBoundingObject = roomLottieObject.highlight.getBoundingClientRect();
          setRoomHoverStyle({
            top: (highlightBoundingObject.y + highlightBoundingObject.height) - ((20 * highlightBoundingObject.height)/100),
            left: (highlightBoundingObject.right - roomHighlightUIWidth) - ((highlightBoundingObject.width - roomHighlightUIWidth)/2),
          });
          setCurrentRoomHover(room);
          setIsRoomHovered(true);
          toggleHighlight(room, true);
          moveLottieLayers(room, true);
          //browser specific lottie patch
          switch (browser && browser.name) {
            case 'safari':
            case 'firefox':
              lottieSafariFirefoxBugLottiePatch();
              break;
            default:
              break;
          }
          document.body.style.cursor = "pointer";
        });

        roomLottieObject.addEventListener("mouseout", function () {
          setIsRoomHovered(false);
          toggleHighlight(room, false);
          moveLottieLayers(room, false);
          //browser specific lottie patch
          switch (browser && browser.name) {
            case 'safari':
            case 'firefox':
              lottieSafariFirefoxBugLottiePatch();
              break;
            default:
              break;
          }
          document.body.style.cursor = "default";
        });
      });
    }

    dispatch({ type: ActionType.SHOW_SIDE_NAV });
  };

  const filterMapMarkersByType = (filterType) => {
    const lottieMap = document.getElementById("lottieMap");
    if (!filterType || !lottieMap) {
      return;
    }
    var classesToShow = [
      "marker_market",
      "marker_offers",
      "marker_smart",
      "marker_tips",
    ];
    var classesToFilter = [];

    if (filterType === "none") {
      //show all markers
      classesToShow.map((filterClass) => {
        var markers = lottieMap.getElementsByClassName(filterClass);
        for (let marker of markers) {
          scaleMarker(marker, 0.49999);
          marker.isScaledUp = false;
          marker.classList.remove("hide");
        }
      });
    } else {
      //construct what markers are filtered/visible
      switch (filterType) {
        case "special offers":
          classesToFilter = ["marker_market", "marker_tips", "marker_smart"];
          classesToShow = "marker_offers";
          break;
        case "smart home":
          classesToFilter = ["marker_market", "marker_offers", "marker_tips"];
          classesToShow = "marker_smart";
          break;
        case "marketplace":
          classesToFilter = ["marker_offers", "marker_tips", "marker_smart"];
          classesToShow = "marker_market";
          break;
        case "tips":
          classesToFilter = ["marker_market", "marker_offers", "marker_smart"];
          classesToShow = "marker_tips";
          break;
      }

      //add opacity class to all filtered hotspots
      classesToFilter.map((filterClass) => {
        var markers = lottieMap.getElementsByClassName(filterClass);
        for (let marker of markers) {
          scaleMarker(marker, 0.49999);
          marker.isScaledUp = false;
          marker.classList.add("hide");
        }
      });

      //remove opacity class to all filtered hotspots
      var visibleMarkers = lottieMap.getElementsByClassName(classesToShow);
      for (let visibleMarker of visibleMarkers) {
        scaleMarker(visibleMarker, 0.64998);
        visibleMarker.isScaledUp = true;
        visibleMarker.classList.remove("hide");
      }
    }
  };

  const scaleMarker = (marker, scale) => {
    var style = window.getComputedStyle(marker);
    var matrixString = style.getPropertyValue('transform');
    var matrixArray = matrixToArray(matrixString);
    if (!matrixArray) {
      return;
    }
    var newMatrixString = "matrix("+scale+", "+matrixArray[1]+", "+matrixArray[2]+", "+scale+", "+matrixArray[4]+", "+matrixArray[5]+")"
    marker.style.transform = newMatrixString;
  };

  const getRoomPercentage = (roomId) => {
    if (!roomId) {
      return;
    }
    let roomTotal = 0;
    if (season === "hot") {
      roomTotal = constants.roomMap.find((x) => x.id === roomId).numberOfMarkersHot;
    } else {
      roomTotal = constants.roomMap.find((x) => x.id === roomId).numberOfMarkersCold;
    }
    let roomCurrent = state.visitedMarkers.filter((x) => x.room.id === roomId).length;
    return Math.round((roomCurrent / roomTotal) * 100);
  };

  //lottie freaks out the first time it has a transform animation transition on fire fox and safari. this functions applies transition classes after first time touch to avoid lottie freakout. This is a dumb fix but lottie it's lotties fault ¯\_(ツ)_/¯
  var lottieTouches = 0;
  const lottieSafariFirefoxBugLottiePatch = () => {
    lottieTouches = lottieTouches + 1;
    if (lottieTouches == 3) {
      addInteractionClassToLottieLayers();
    }
  }

  const addInteractionClassToLottieLayers = () => {
    //add transiton easing for layers after animation is complete. if added at start it will mess up lottie animation
    //right only chrome supprts certain animation features for lottie.
    switch (browser && browser.name) {
      case 'safari':
      case 'firefox':
      case 'opera':
        lottieElements.map(element => {
          var lottieElement = document.getElementById(element.id);
          // lottieElement.style.transition = "opacity 0.2s";
          lottieElement.style.transition = "0.2s";
        });
        for (let marker of allMarkers) {
          // marker.style.transition = "opacity 0.2s";
          marker.style.transition = "0.2s";
        }
        break;
      default:
        lottieElements.map(element => {
          var lottieElement = document.getElementById(element.id);
          lottieElement.style.transition = "0.2s";
        });
        for (let marker of allMarkers) {
          marker.style.transition = "0.2s";
        }
    }
  }

  const toggleHighlight = (room, highlightActive) => {
    var highlight = document.getElementById(room.highlightID);
    if(highlightActive) {
      highlight.classList.add("show");
    } else {
      highlight.classList.remove("show");
    }
  }

  const moveLottieLayers = (room, isSeperating) => {
    var floor = room.floor;

    //move rooms, floors and shadows
    lottieElements.map((element, index) => {
      var lottieElement = document.getElementById(element.id);
      applyMatrixTranslation(lottieElement, room, element.interaction, floor, isSeperating, false);
    });
    //move all markers
    moveMarkers(room, floor, isSeperating);
  }

  //determine which marker class is on the hovered floor
  const moveMarkers = (room, floor, isSeperating) => {
    //translate corresponding floor to marker class
    switch (floor) {
      case "attic":
        var highlightedFloorClass = "marker_attic";
        break;
      case "floor2":
        var highlightedFloorClass = "marker_floor2";
        break;
      case "floor1":
        var highlightedFloorClass = "marker_floor1";
        break;
      case "basement":
        var highlightedFloorClass = "marker_basement";
        break;
      default:
    }

    //if marker not on hitbox hovered floor - move accordingly based on corresponding interaction object
    for (let marker of allMarkers) {
      var markerOnHighlightedFloor = marker.classList.contains(highlightedFloorClass);
      switch (true) {
        case marker.classList.contains("marker_attic"):
          applyMatrixTranslation(marker, room, atticInteraction, floor, isSeperating, true);
          break;
        case marker.classList.contains("marker_floor2"):
          applyMatrixTranslation(marker, room, floor2Interaction, floor, isSeperating, true);
          break;
        case marker.classList.contains("marker_floor1"):
          applyMatrixTranslation(marker, room, floor1Interaction, floor, isSeperating, true);
          break;
        case marker.classList.contains("marker_basement"):
          applyMatrixTranslation(marker, room, basementInteraction, floor, isSeperating, true);
          break;
        default:
      }
    }
  }

  const applyMatrixTranslation = (element, room, interaction, floor, isSeperating, isMarker) => {
    //if first time applying matrix - get current matrix and calculate down and up translation
    if (element.originalMatrix === undefined && element.upMatrix === undefined && element.upMatrix === undefined) {
      var matrixString = element.getAttribute("transform");
      var matrixArray = matrixToArray(matrixString);

      //determines the amount the transletion will travel
      var shiftAmount = 25;

      //create matrix strings for up and down translation
      var newUpMatrixString = "matrix("+matrixArray[0]+", "+matrixArray[1]+", "+matrixArray[2]+", "+matrixArray[3]+", "+(Number(matrixArray[4])-shiftAmount)+", "+(Number(matrixArray[5])-shiftAmount)+")";
      var newDownMatrixString = "matrix("+matrixArray[0]+", "+matrixArray[1]+", "+matrixArray[2]+", "+matrixArray[3]+", "+(Number(matrixArray[4])+shiftAmount)+", "+(Number(matrixArray[5])+shiftAmount)+")";

      //create matrix strings for up and down translation when scaled up
      var matrixStringScaled = "matrix( 0.64998, "+matrixArray[1]+", "+matrixArray[2]+", 0.64998, "+matrixArray[4]+", "+matrixArray[5]+")";
      var newUpMatrixStringScaled = "matrix( 0.64998, "+matrixArray[1]+", "+matrixArray[2]+", 0.64998, "+(Number(matrixArray[4])-shiftAmount)+", "+(Number(matrixArray[5])-shiftAmount)+")";
      var newDownMatrixStringScaled = "matrix( 0.64998, "+matrixArray[1]+", "+matrixArray[2]+", 0.64998, "+(Number(matrixArray[4])+shiftAmount)+", "+(Number(matrixArray[5])+shiftAmount)+")";

      //assign values to elemnt to grab later
      element.originalMatrix = matrixString
      element.upMatrix = newUpMatrixString;
      element.downMatrix = newDownMatrixString;

      //scaled
      element.originalMatrixScaled = matrixStringScaled
      element.upMatrixScaled = newUpMatrixStringScaled;
      element.downMatrixScaled = newDownMatrixStringScaled;
    }

    //move matrix accordingly
    if (isSeperating) {
      switch (interaction[floor]) {
        case "down":
          if (isMarker && (element.isScaledUp === true)) {
            element.style.opacity = 0.6;
            element.style.transform = element.downMatrixScaled;
          } else {
            element.style.opacity = 0.6;
            element.style.transform = element.downMatrix;
          }
          break;
        case "up":
          if (isMarker && (element.isScaledUp === true)) {
            element.style.opacity = 0.6;
            element.style.transform = element.upMatrixScaled;
          } else {
            element.style.opacity = 0.6;
            element.style.transform = element.upMatrix;
          }
          break;
        case "none":
          //lower opacity of item on same floor but not part of hovered room
          if (room.id === element.id || element.id === room.floorID || element.classList.contains(room.markerID)) {
            element.style.opacity = 1.0;
          } else {
            element.style.opacity = 0.6;
          }
        default:
      }
    } else {
      if (isMarker && (element.isScaledUp === true)) {
        element.style.opacity = 1.0;
        element.style.transform = element.originalMatrixScaled;
      } else {
        element.style.opacity = 1.0;
        element.style.transform = element.originalMatrix;
      }
    }
  }

  const matrixToArray = (str) => {
    return str.match(/(-?[0-9\.]+)/g);
  }

  const navigateToRoom = (room) => {
    history.push(room.url);
    dispatch({ type: ActionType.UPDATE_MAP_PAGE_CHANGE, payload: room });
  }

  const switchMobileRoom = (isGoingBackwards) => {
    let index = lottieRoomIDs.findIndex(x => x.roomId === mobileRoomSelected.roomId);
    let max = lottieRoomIDs.length - 1;
    let newIndex;
    if (isGoingBackwards && (index - 1) < 0) {
      newIndex = max;
    } else if (!isGoingBackwards && (index + 1) > max) {
      newIndex = 0;
    } else {
      newIndex = isGoingBackwards ? index - 1 : index + 1;
    }

    setMobileRoomSelected(lottieRoomIDs[newIndex]);
  }

  var roomHoverClasses = classNames({
    roomHoverContent: true,
    active: isRoomHovered,
  });

  var roomHoverFadeClass = classNames({
    roomHoverFade: true,
    active: isRoomHovered,
  })

  var lottieInteractionClasses = classNames({
    lottieAnimations: true,
    preventLottieInteraction: mapAnimationFinished === false
  });

  var lottieContainerClasses = classNames({
    lottieContainer: true,
    lottieSideBarPadding: mapAnimationFinished === true || state.mapPageChange == "HOME"
  });

  var exploreTextContainerClasses = classNames({
    exploreTextContainer: true,
    visible: mapAnimationFinished === true,
  });

  var startButtonClasses = classNames({
    startButton: true,
    startButtonActive: mapIsDisplayed === false,
  });

  var textAreaClasses = classNames({
    textArea: true,
    hideTextArea: mapIsDisplayed
  })

  //determine that user is using a supported browser
  switch (browser && browser.name) {
    case 'ie':
    case 'opera':
    case 'edge':
      var content = (
        <div className="container">
          <div className="containerTop">
            <div className="containerContent">
              <div className="nsInto">
                <img className="dteTitle" src="/assets/logo/dteLogo.svg" />
              <div className="nsTitle">Your Browser does not support the DTE Interactive Energy Efficient Home</div>
                <div className="nsBody">Supported browsers are the most recent versions of Chrome, Safari, Edge or Firefox. If you are using one these browser, please update to the latest version.</div>
                <a href="https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/save-energy/residential/start+saving/ways+to+save">
                  <div className="nsButton">Visit New to Energy Savings</div>
                </a>
              </div>
              <img className="nsHouse" src="/assets/not_supported/nsHouse.svg" />
            </div>
          </div>
        </div>
      );
      break;
    default:
      var houseLoadingAnimation = null;
      var mapLoadingAnimation = null;
      if (season === 'cold') {
        houseLoadingAnimation = houseLoadingColdOptions;
        mapLoadingAnimation = mapHouseOptionsCold;
      } else {
        houseLoadingAnimation = houseLoadingOptions;
        mapLoadingAnimation = mapHouseOptions;
      }

      const videoThumbailClasses = () => {
        let classes = ['videoWrapper'];
        
        if(window.innerWidth <= mobileBreakpoint) {
          classes.push('videoPositionMobile');
        } else {
          classes.push('videoPosition');
        }

        if(mapIsDisplayed) {
          classes.push('videoReveal');
        } else {
          classes.push('videoConceal');
        }

        return classes.join(' ');
      }

      var content = (
        <div className="container">
          <MetaTags>
            <title>DTE Interactive Home</title>
            <meta name="description" content="DTE interactive house map. Explore an interactive house and discover what savings you could bring to your household." />
          </MetaTags>
          <div className="MapPage">
            <div className={ videoThumbailClasses() }>
              <VideoThumbail video={ videoCard } />
            </div>
            {state.mapPageChange == "HOME" ? null :
            <animated.div className={textAreaClasses}>
              <div className="presents">
                <img className="dteTitle" src="/assets/logo/dteLogo.svg" />
              </div>
              <div className="title">
                <div className="titleFirstLine">Explore Our Interactive</div>
              <div className="titleSecondLine">Energy Efficient Home</div>
              </div>
              <div className="landingHorizontalLine" />
              <div className="subTitle">
                Discover tips, offers and smart home products you can use to save money and energy in your home!
              </div>
              <div className="social">
                <SocialLinks {...{ sharelink }} />
              </div>
            </animated.div>}
            <div className={lottieContainerClasses}>
              {mapIsDisplayed && (
                <div className={exploreTextContainerClasses}>
                  <div className="exploreTitle">Begin Your Journey</div>
                  <div className="exploreTitleBelow">Select a room to explore in 3D</div>
                </div>
              )}
              <div className="lottieAnimation">
                <div className={lottieInteractionClasses}>
                  {mapIsDisplayed === false && (
                    <Lottie options={houseLoadingAnimation} />
                  )}
                  {mapIsDisplayed && (
                    <div id="lottieMap">
                      <Lottie
                        options={mapLoadingAnimation}
                        isPaused={isStopped}
                        eventListeners={[
                          {
                            eventName: "complete",
                            callback: () => {
                              if (!isLottieComplete) {
                                lottieInteractionInit();
                                isLottieComplete = true;
                                if (window.innerWidth <= mobileBreakpoint) {
                                  setMobileFooterSpring({ bottom: 0 });
                                }
                              }
                            },
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
                <button className={startButtonClasses} onClick={startExploreHome}>
                  <div className="startButtonText">Begin Your Journey</div>
                  <div className="startButtonPulseRing2"></div>
                  <div className="startBackground"></div>
                </button>
              </div>
            </div>
            {mapIsDisplayed && window.innerWidth > mobileBreakpoint ? (
              <div className={roomHoverFadeClass} style={roomHoverStyle} id="roomHighlightUI">
                <div className={roomHoverClasses}>
                  <div className="roomHoverContentContainer">
                    <div className="roomHoverCurrentRoom">
                      {currentRoomHover?.displayName}
                    </div>
                    <div className="roomHoverCompletion">
                      Progress: {getRoomPercentage(currentRoomHover?.roomId)}%
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <animated.div className="mobileFooter" style={mobileFooterSpring}>
                <div className="displayArea">
                  <div className="arrowButton arrowLeft" onClick={() => switchMobileRoom(true)}>
                    <img src="/img/Icons/backCarat.svg" className="arrow" />
                  </div>
                  <div className="displayText">
                    <div className="mobileDisplayName">{mobileRoomSelected?.displayName}</div>
                    <div className="mobilePercentage">
                      Progress: {getRoomPercentage(mobileRoomSelected?.roomId)}%
                    </div>
                  </div>
                  <div className="arrowButton arrowRight" onClick={() => switchMobileRoom(false)}>
                    <img src="/img/Icons/forwardCarat.svg" className="arrow" />
                </div>
              </div>
              <div className="viewRoomButton" onClick={() => navigateToRoom(mobileRoomSelected)}>
                View {mobileRoomSelected?.displayName}
              </div>
            </animated.div>
          </div>
          <animated.div className="mapPageOverlay" style={overlaySpring}></animated.div>
        </div>
      );
  }

  return (
    <div>
      {content}
    </div>
  );
}

export default MapPage;
