import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import { useParams } from "react-router-dom";
import map from "../../roommap";
import RoomCard from "./RoomCard";
import "./RoomPage.css";
import ThreeRoom from "./ThreeRoom/ThreeRoom";
import { AppContext } from "../../store/context";
import { ActionType } from "../../store/actions";
import ExploreRoomModal from "../Modals/ExploreRoomModal/ExploreRoomModal";
import ProgressRingsService from "../../ProgressRingsService";
import cms from "../../cmsservice";
import {animated} from "react-spring";
import { constants } from "../Common/constants";
var classNames = require("classnames");
const { detect } = require('detect-browser');
const browser = detect();

function RoomPage() {
  const { room } = useParams();
  const [roomName, setRoomName] = useState(0);
  const [cardInfo, setCardInfo] = useState(null);
  const [loading, setLoading] = useState(0);
  const [loadtips, setLoadtips] = useState([]);
  const [modalNotReady, setModalNotReady] = useState(true);
  const [shouldHideLoader, setShouldHideLoader] = useState(false);
  const page = useRef(null);
  const { state, dispatch } = useContext(AppContext);
  state.roomHasBeenVisited = true;

  useEffect(() => {
    //TODO: move dispatch to after page loads in ThreeRoom
    const mappedroom = map(room);
    if (mappedroom.id > 0) dispatch({ type: ActionType.SHOW_SIDE_NAV });
    else dispatch({ type: ActionType.HIDE_SIDE_NAV });
    setRoomName(mappedroom);
    dispatch({ type: ActionType.SET_CURRENT_ROOM_ID, payload: mappedroom.id });
    cms.getEntries({ content_type: "loadingScreenTips" }).then((tips) => {
      setLoadtips(
        tips.items
          .map((tip) => tip.fields.content)
          .sort((a, b) => 0.5 - Math.random())
      );
    });
  }, [room]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_LOADING, payload: loading });
    if (loading > 99 && roomName.id > 0) {
      setShouldHideLoader(true);
      setTimeout(function() {
        setModalNotReady(false);
      }, (4000));
    } else {
      setShouldHideLoader(false);
    }
  }, [loading]);

  // check if there are any new compelted marker types and add them to completed marker types
  // dispatch a SHOW_COMPLETED_MODAL if there's a new completed marker type and if
  // it's not the first marker clicked on
  const getCompletedMarkerTypes = (shouldShowModal) => {
    var newlyCompleted = ProgressRingsService(state).filter(
      (x) =>
        x.isComplete && !state.completedMarkerTypes.some((y) => y === x.type)
    );

    if (newlyCompleted.some((x) => x) && newlyCompleted.length === 1) {
      var completedTypes = state.completedMarkerTypes;
      completedTypes.push(newlyCompleted[0].type);
      if (shouldShowModal) {
        dispatch({
          type: ActionType.SHOW_COMPLETED_MODAL,
          payload: newlyCompleted[0].type,
        });
      }
      return completedTypes;
    }

    return state.completedMarkerTypes;
  };

  // get payload for dispatching UPDATE_VISTED_MARKERS
  const getPayload = (visitedMarkerArray, markerObject) => {
    var firstMarkerType = null;
    if (state.visitedMarkers.length === 1) {
      firstMarkerType = markerObject.markerType;
    }
    var payload = {
      visitedMarkers: visitedMarkerArray,
      firstMarkerType: firstMarkerType,
      completedMarkerTypes: getCompletedMarkerTypes(firstMarkerType == null),
    };
    return payload;
  };

  const markerCheckVisited = (marker) => {
    const markerObject = {
      name: marker.name,
      markerType: marker.markerType,
      id: marker.markerID,
      room: roomName,
    };

    let visitedMarkerArray = state.visitedMarkers;

    // add marker if it has not been visited before
    if (
      !state.visitedMarkers.some((marker) => marker.name === markerObject.name)
    ) {
      visitedMarkerArray.push(markerObject);
      dispatch({
        type: ActionType.UPDATE_VISTED_MARKERS,
        payload: getPayload(visitedMarkerArray, markerObject),
      });
      addToProgressTotal(marker);
    }

    constants.gmt({
      state: { visitedMarkers: visitedMarkerArray },
      dataTrackDetail: constants.analytics.markerselected,
      dataTrackSubdetail: marker.name,
      dataTrackAction: constants.analytics.click,
      dataTrackSubaction: roomName.displayName,
    });
  };

  var loadingContainer404 = classNames({
    loading404: true,
  });

  var loadingContainer = classNames({
    loadingContainer: true,
    hideLoading: shouldHideLoader
  });

  //add to tracking total when first visit on marker
  const addToProgressTotal = (marker) => {
    cms.getEntry(marker.objectInformation.sys.id).then(data => {

      var itemToTrack = [];
      //if fields for tracking are not null add them to item track array
      if (data.fields.treesPlanted) {
        itemToTrack.push({
          type: "treesPlanted",
          value: data.fields.treesPlanted
        })
      }
      if (data.fields.bagsOfWasteRecycled) {
        itemToTrack.push({
          type: "bagsOfWasteRecycled",
          value: data.fields.bagsOfWasteRecycled
        })
      }
      if (data.fields.gallonsOfGasSaved) {
        itemToTrack.push({
          type: "gallonsOfGasSaved",
          value: data.fields.gallonsOfGasSaved
        })
      }

      itemToTrack.map(item => {
        switch (item.type) {
          case "treesPlanted":
            dispatch({
              type: ActionType.UPDATE_PROGRESS_TRACKER,
              payload: {
                amount: Number(item.value),
                tracker: "treesPlanted"
              }
            });
            break;
          case "bagsOfWasteRecycled":
            dispatch({
              type: ActionType.UPDATE_PROGRESS_TRACKER,
              payload: {
                amount: Number(item.value),
                tracker: "bagsOfWasteRecycled"
              }
            });
            break;
          case "gallonsOfGasSaved":
            dispatch({
              type: ActionType.UPDATE_PROGRESS_TRACKER,
              payload: {
                amount: Number(item.value),
                tracker: "gallonsOfGasSaved"
              }
            });
            break;
          default:
            break;
        }
      });

    });
  }

  //determine that usuer is using a supported browser
  switch (browser && browser.name) {
    case 'ie':
    case 'opera':
    case 'edge':
      var content = (
        <div className="container">
          <div className="containerTop">
            <div className="containerContent">
              <div className="nsInto">
                <img className="dteTitle" src="/assets/logo/dteLogo.svg" />
              <div className="nsTitle">Your Browser does not support the DTE Interactive Energy Efficient Home</div>
                <div className="nsBody">Supported browsers are the most recent versions of Chrome, Safari, Edge or Firefox. If you are using one these browser, please update to the latest version.</div>
                <a href="https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/save-energy/residential/start+saving/ways+to+save">
                  <div className="nsButton">Visit New to Energy Savings</div>
                </a>
              </div>
              <img className="nsHouse" src="/assets/not_supported/nsHouse.svg" />
            </div>
          </div>
        </div>
      );
      break;
    default:
      var content = (
        <div>
          <div className="RoomPage" ref={page}>
            <ThreeRoom
              roomID={roomName.id}
              {...{ setCardInfo, setLoading }}
              markerClick={markerCheckVisited}
            />
            {loading > 99 && (
              <Fragment>
                <RoomCard {...{ cardInfo, roomName } } />
              </Fragment>
            )}
            {roomName.id > 0 && <animated.div
              className={loadingContainer}
            >
              <div className="loadBackground"></div>
              <div className="loadingBarContainer">
                <div className="nowLoadingContainer">
                  <div className="nowLoading">Now Loading...</div>
                  <div className="roomLoading">The {roomName.displayName}</div>
                </div>

                <div className="progressback">
                  <div
                    className="progressfront"
                    style={{ width: loading + "%" }}
                  ></div>
                </div>

                <div className="loadtips">
                  <div className="loadDidYouKnow">Did You Know?</div>
                  <div className="loadtip">{loadtips[0]}</div>
                </div>
              </div>
            </animated.div>}
            {roomName.id < 1 && (
              <div className={loadingContainer404}>
                <div className="loadBackground"></div>
                <div className="loadingBarContainer">
                  <div className="nowLoadingContainer">
                    <div>Cannot Load Room</div>
                  </div>

                  <div className="loadtips">
                    <div className="loadDidYouKnow">
                      The room name is not recognized
                    </div>
                    <div className="loadtip">
                      Would you like to return to the&nbsp;
                      <a href={window.location.origin}>Home Page</a>
                      &nbsp;and explore further?
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {modalNotReady ? "" : <ExploreRoomModal />}
        </div>
      );
  }

  return (
    <div>
      {content}
    </div>
  );
}

export default RoomPage;
