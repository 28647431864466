import React from "react";
import "./DteModal.css";
import Modal from "react-modal";

Modal.setAppElement("#root");

function DteModal(props) {
  const isMobile = window.innerWidth <= 1008;
  const defaultModalStyle = {
    content: {
      width: isMobile ? "70%" : (props.width ?? "700px"),
      height: isMobile ? "auto" : "auto",
      bottom: "0",
      left: "0",
      right: "0",
      top: "0",
      margin: "auto",
      display: "flex",
      position: "relative",
    },
    overlay: {
      backgroundColor: "rgba(30, 53, 117, 0.2)",
      zIndex: "9999"
    },
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={isMobile ? null : () => props.onClose('click away')}
      style={props.modalStyle ?? defaultModalStyle}
    >
      <div className="close" onClick={() => props.onClose('x out')}>
        <img src="img/Icons/close.svg" />
      </div>
      {props.children}
    </Modal>
  );
}

export default DteModal;
