import React, { useContext } from "react";
import "./ExploreRoomModal.css";
import DteModal from "../../Common/DteModal/DteModal";
import { AppContext } from "../../../store/context";
import { ActionType } from "../../../store/actions";
import { constants } from "../../Common/constants";
import map from "../../../roommap";

function ExploreRoomModal() {
  const { state, dispatch } = useContext(AppContext);

  const setRoomAsVisited = (method) => {
    dispatch({ type: ActionType.SET_ROOM_AS_VISITED });
    constants.gmt({
      state,
      dataTrackDetail: constants.analytics.exploreroom,
      dataTrackSubdetail: constants.roomMap.filter(r => r.id === state.currentRoomId).map(r => map(r.name).displayName)[0] || 'Unknown',
      dataTrackAction: constants.analytics.click,
      dataTrackSubaction: method,
    });
  };

  return (
    <DteModal
      isOpen={!state.hasVisitedRoom}
      onClose={mtd => setRoomAsVisited(mtd)}
      width={"450px"}
      height={"auto"}
    >
      <div className="exploreModalContent">
        <div className="image">
          <img style={{ height: 60 }} src="img/Icons/explore.svg"></img>
        </div>
        <div className="exploreTitle">Explore the room!</div>
        <div className="exploreSubTitle">
          Click and drag anywhere in the room to look around. Click on the various icons to learn more about that item and potential energy savings.
        </div>
        <div className="modalButton" onClick={() => setRoomAsVisited('button')}>Let's Get Started!</div>
      </div>
    </DteModal>
  );
}

export default ExploreRoomModal;
