import React, { useState, useContext, useEffect } from "react";
import "./SideNav.css";
import { AppContext } from "../../store/context";
import ProgressRing from "../Common/ProgressRing";
import ProgressRingService from "../../ProgressRingsService";
import { ActionType } from "../../store/actions";
import MiniMap from "../Common/MiniMap";
import { useSpring, animated } from "react-spring";
import { useLocation } from "react-router-dom";
import cms from "../../cmsservice";
import { constants } from "../Common/constants";
import SocialLinks from "../Common/SocialLinks";
import { useHistory } from "react-router-dom";
var classNames = require("classnames");

function SideNav() {
  const [activeFilter, setActiveFilter] = useState("none");
  const [isActive, setIsActive] = useState(0);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [sharelink, setSharelink] = useState("");
  const { state, dispatch } = useContext(AppContext);
  const location = useLocation();
  let isOnMapPage = useLocation().pathname == "/";
  const progressRings = ProgressRingService(state, constants.ringType.side);
  const history = useHistory();

  if (!isOnMapPage) {
    //get room info
    cms
      .getEntries({
        content_type: "room",
      })
      .then(function (response) {
        var rooms = response.items;
        var currentRoom = rooms.find(
          (room) => room.fields.roomId === state.currentRoomId
        );
        setCurrentRoom(currentRoom);
      });
  }

  useEffect(() => {
    const url = new URL(window.location.href);
    setSharelink(url.origin + url.pathname);
  }, [location]);

  useEffect(() => {
    // this is for sliding out the side nav when
    // not on mobile on the map page after you click
    // "explore the home"
    if (window.innerWidth > 1008) {
      toggleExpandCollapse(true);
    }
  }, [state.isSideNavVisible]);

  const miniMapSize = isOnMapPage ? 200 : 115;
  // prettier-ignore
  const expandedStyles = {
    navSpring: {width: 200, padding: 15},
    collapseIconSpring: {left: 230},
    hrSpring: {width: 200},
    mapSpring: isOnMapPage ? {width: miniMapSize} : {height: miniMapSize, width: miniMapSize},
    buttonSpring: {width: 188},
    iconSpring: {transform: "rotate(0deg)"},
    energyTextSpring: {height: 76, overflow: "hidden"},
  };
  // prettier-ignore
  const collapsedStyles = {
    navSpring: {width: 52, padding: 15},
    collapseIconSpring: {left: 82},
    hrSpring: {width: 52},
    mapSpring: isOnMapPage ? {width: 52} : {height: 52, width: 52},
    buttonSpring: {width: 40},
    iconSpring: {transform: "rotate(-180deg)"},
    energyTextSpring: {height: 0, overflow: "hidden"},
  };
  // prettier-ignore
  const collapsedStylesMobile = {
    navSpring: { width: 0, padding: 0 },
    collapseIconSpring: { left: 0 },
    hrSpring: { width: 0 },
    mapSpring: { width: 0 },
    buttonSpring: { width: 0 },
    iconSpring: { transform: "rotate(-180deg)" },
    energyTextSpring: {height: 0, overflow: "hidden"},
  };
  const mobileBaseWidth = window.innerWidth * 0.7;
  // prettier-ignore
  const expandedStylesMobile = {
    navSpring: {width: mobileBaseWidth, padding: 15},
    collapseIconSpring: {left: mobileBaseWidth + 30},
    hrSpring: {width: mobileBaseWidth},
    mapSpring: isOnMapPage ? {width: mobileBaseWidth - 80, height: "auto", paddingBottom: 20} : {width: mobileBaseWidth - 150, height: mobileBaseWidth - 150, paddingBottom: 0},
    buttonSpring: {width: mobileBaseWidth - 12},
    iconSpring: {transform: "rotate(0deg)"},
    energyTextSpring: {height: 76, overflow: "hidden"},
  };
  // prettier-ignore
  const collapsedStylesSlideOutMobile = {
    navSpring: {width: mobileBaseWidth, padding: 15, left: -(mobileBaseWidth + 30)},
    collapseIconSpring: { left: 0 },
    hrSpring: {width: 200},
    mapSpring: isOnMapPage ? {width: miniMapSize} : {height: miniMapSize, width: miniMapSize},
    buttonSpring: {width: 188},
    iconSpring: { transform: "rotate(-180deg)" },
    energyTextSpring: {height: 76, overflow: "hidden"},
  };
  // prettier-ignore
  const expandedStylesSlideOutMobile = {
    navSpring: {width: mobileBaseWidth, padding: 15, left: 0},
    collapseIconSpring: {left: mobileBaseWidth + 30},
    hrSpring: {width: mobileBaseWidth},
    mapSpring: isOnMapPage ? {width: mobileBaseWidth - 80, height: "auto", paddingBottom: 20} : {width: mobileBaseWidth - 150, height: mobileBaseWidth - 150, paddingBottom: 0},
    buttonSpring: {width: mobileBaseWidth - 12},
    iconSpring: {transform: "rotate(0deg)"},
    energyTextSpring: {height: 76, overflow: "hidden"},
  };
  // prettier-ignore
  const collapsedStylesSlideOut = {
    navSpring: {width: 200, padding: 15, left: -230},
    collapseIconSpring: {left: 230},
    hrSpring: {width: 200},
    mapSpring: isOnMapPage ? {width: miniMapSize} : {height: miniMapSize, width: miniMapSize},
    buttonSpring: {width: 188},
    iconSpring: { transform: "rotate(-180deg)" },
    energyTextSpring: {height: 76, overflow: "hidden"},
  };
  // prettier-ignore
  const expandedStylesSlideOut = {
    navSpring: {width: 200, padding: 15, left: 0},
    collapseIconSpring: {left: 230},
    hrSpring: {width: 200},
    mapSpring: isOnMapPage ? {width: miniMapSize} : {height: miniMapSize, width: miniMapSize},
    buttonSpring: {width: 188},
    iconSpring: {transform: "rotate(0deg)"},
    energyTextSpring: {height: 76, overflow: "hidden"},
  };

  const [navSpring, setNavSpring] = useSpring(() => state.isSideNavExpanded && state.isSideNavVisible ? expandedStyles.navSpring : collapsedStylesSlideOut.navSpring);
  const [collapseIconSpring, setCollapseIconSpring] = useSpring(
    () => state.isSideNavExpanded && state.isSideNavVisible ? expandedStyles.collapseIconSpring : collapsedStylesMobile.collapseIconSpring
  );
  const [hrSpring, setHrSpring] = useSpring(() => state.isSideNavExpanded && state.isSideNavVisible ? expandedStyles.hrSpring : collapsedStylesSlideOut.hrSpring);
  const [mapSpring, setMapSpring] = useSpring(() => state.isSideNavExpanded && state.isSideNavVisible ? expandedStyles.mapSpring : collapsedStylesSlideOut.mapSpring);
  const [buttonSpring, setButtonSpring] = useSpring(
    () => state.isSideNavExpanded && state.isSideNavVisible ? expandedStyles.buttonSpring : collapsedStylesSlideOut.buttonSpring
  );
  const [iconSpring, setIconSpring] = useSpring(
    () => state.isSideNavExpanded && state.isSideNavVisible ? expandedStyles.iconSpring : collapsedStylesSlideOut.iconSpring
  );
  const [energyTextSpring, setEnergyTextSpring] = useSpring(() => state.isSideNavExpanded && state.isSideNavVisible ? expandedStyles.energyTextSpring : collapsedStylesSlideOut.energyTextSpring);

  // prettier-ignore
  const toggleExpandCollapse = (shouldSlideOut = false) => {
    let style;

    if (window.innerWidth <= 1008 && shouldSlideOut) {
      style = !state.isSideNavExpanded ? expandedStylesSlideOutMobile : collapsedStylesSlideOutMobile;
    } else if (window.innerWidth <= 1008 && !shouldSlideOut) {
      style = !state.isSideNavExpanded ? expandedStylesMobile : collapsedStylesMobile;
    } else if (window.innerWidth > 1008 && shouldSlideOut) {
      style = !state.isSideNavExpanded ? expandedStylesSlideOut : collapsedStylesSlideOut;
    } else {
      style = !state.isSideNavExpanded ? expandedStyles : collapsedStyles;
    }
    setNavSpring(style.navSpring);
    setCollapseIconSpring(style.collapseIconSpring);
    setHrSpring(style.hrSpring);
    setMapSpring(style.mapSpring);
    setButtonSpring(style.buttonSpring);
    setIconSpring(style.iconSpring);
    setEnergyTextSpring(style.energyTextSpring);

    dispatch({ type: ActionType.TOGGLE_SIDE_NAV_EXPANDED });
  };

  useEffect(() => {
    // set the mini map house image to the correct size
    // after navigating home from a room page or
    // from a room page to home
    let isNowOnMapPage = state.mapPageChange == "HOME";
    isOnMapPage = isNowOnMapPage;
    let homeMapStyles;
    if (isNowOnMapPage) {
      if (window.innerWidth <= 1008) {
        homeMapStyles = {width: (window.innerWidth * 0.7) - 50, height: 75}
      } else {
        homeMapStyles = {height: 115, width: 200, maxWidth: 200}
      }
    } else if (state.mapPageChange != null) {
      if (window.innerWidth <= 1008) {
        homeMapStyles = {width: (window.innerWidth * 0.6) - 150, height: (window.innerWidth * 0.6) - 150}
      } else {
        homeMapStyles = {height: 115, width: 115, maxWidth: 115}
      }
    }

    setMapSpring({to: homeMapStyles, from: homeMapStyles});


    // clear filter when navigating home
    clearFilters();
  }, [state.mapPageChange]);

  const clearFilters = () => {
    filterButtonClicked(state.filterType, filterButtonsInfo.findIndex(x => x.value === state.filterType).id);
  }

  const setIsOnMapPage = (isOnMap) => {
    isOnMapPage = isOnMap
  };

  const navigateHome = () => {
    if (!state.loading || state.loading < 100) {
      return;
    }
    dispatch({type: ActionType.UPDATE_MAP_PAGE_CHANGE, payload:"HOME"});
  }

  const filterButtonClicked = (filterValue, filterID) => {
    if (activeFilter === filterValue) {
      setActiveFilter("none");
      setIsActive(0);
      dispatch({ type: ActionType.SET_MARKER_FILTER_TYPE, payload: "none" });
    } else {
      setActiveFilter(filterValue);
      setIsActive(filterID);
      dispatch({ type: ActionType.SET_MARKER_FILTER_TYPE, payload: filterValue});
      constants.gmt({
        state,
        dataTrackDetail: constants.analytics.highlight,
        dataTrackSubdetail: filterValue,
        dataTrackAction: constants.analytics.click,
        dataTrackSubaction: ((currentRoom || {}).fields || {}).roomName || '',
      });
    }
  };

  const showProgress = () => {
    dispatch({ type: ActionType.TOGGLE_PROGRESS_MODAL });
  };

  const filterButtonsInfo = [
    {
      id: 1,
      value: "tips",
      displayName: "Tips",
    },
    {
      id: 2,
      value: "marketplace",
      displayName: "Marketplace",
    },
    {
      id: 3,
      value: "special offers",
      displayName: "Special Offer",
    },
    {
      id: 4,
      value: "smart home",
      displayName: "Smart Home",
    },
  ];

  const getDisplayColor = (type) => {
    switch (type) {
      case constants.markerType.tips:
        return "#80D8D3";
      case constants.markerType.marketplace:
        return "#39AAC8";
      case constants.markerType.specialOffer:
        return "#2E9CFF";
      case constants.markerType.smartHome:
        return "#103DBC";
    }
  };

  const changeRoom = (isGoingForward) => {
    if (!state.loading || state.loading < 100) {
      return;
    }

    dispatch({ type: ActionType.SET_LOADING, payload: 0 });

    let newRoomId = isGoingForward ? state.currentRoomId + 1 : state.currentRoomId - 1;
    if (newRoomId > 10) {
      newRoomId = 1;
    } else if (newRoomId < 1) {
      newRoomId = 10;
    }

    dispatch({ type: ActionType.SET_CURRENT_ROOM_ID, payload: newRoomId });
    let newRoom = constants.roomMap.find((x) => x.id === newRoomId);
    if (newRoom) {
      history.push(`/${newRoom.name}`);
    }
    if (window.innerWidth <= 1008) {
      toggleExpandCollapse();
    }
    clearFilters();
  };

  const getRingMarginTop = (type) => {
    switch(type) {
      case constants.markerType.smartHome:
        return "10px";
      case constants.markerType.specialOffer:
        return "7px";
      default:
        return "8px";
    }
  }

  var buttons = filterButtonsInfo.map((filterInfo, index) => {
    var isButtonActive = isActive === filterInfo.id;
    var buttonClasses = classNames({
      tablet: true,
      activeTips: isButtonActive && filterInfo.value == constants.markerType.tips,
      activeMarketplace: isButtonActive && filterInfo.value == constants.markerType.marketplace,
      activeSpecialOffer: isButtonActive && filterInfo.value == constants.markerType.specialOffer,
      activeSmartHome: isButtonActive && filterInfo.value == constants.markerType.smartHome,
      tips: filterInfo.value == constants.markerType.tips,
      marketplace: filterInfo.value == constants.markerType.marketplace,
      specialOffer: filterInfo.value == constants.markerType.specialOffer,
      smartHome: filterInfo.value == constants.markerType.smartHome,
    });

    return (
      <animated.div
        style={buttonSpring}
        className={buttonClasses}
        id={filterInfo.id}
        key={index}
        value={filterInfo.value}
        onClick={() => filterButtonClicked(filterInfo.value, filterInfo.id)}
      >
        <div className="highlightRing">
          <ProgressRing
            progressRing={progressRings.find((x) => x.type == filterInfo.value)}
            showCount={false}
            iconWidth="16px"
            marginTop={getRingMarginTop(filterInfo.value)}
            isRingActive={isButtonActive}
          />
        </div>
        {state.isSideNavExpanded ? (
          <div className="displayName" style={{ color: getDisplayColor(filterInfo.value) }}>
            {filterInfo.displayName}
          </div>
        ) : (
          ""
        )}
      </animated.div>
    );
  });

  return (
    <div className={state.loading < 100 && !isOnMapPage ? "whiteOverlay" : "whiteOverlaySolid"}>
      <animated.div style={navSpring} className={isOnMapPage && window.innerWidth > 1008 ? "leftNav leftNavShaddowMap" : "leftNav leftNavShaddow"}>
        {(window.innerWidth > 1008 ||
        (window.innerWidth <= 1008 && state.isSideNavExpanded)) && state.isSideNavVisible ? (
          <div>
            <div className="navTitle">
              <img className="dteTitle" src="/assets/logo/dteLogo.svg" />
              {isOnMapPage && state.isSideNavExpanded ? (
                <div className="dteSubTitle">
                  The Energy Efficient <br />{" "}
                  <div className="strongInteractive">Interactive Home</div>
                </div>
              ) : (
                ""
              )}
            </div>
            <animated.div className="miniMap" style={mapSpring}>
              <MiniMap room={state.currentRoomId} isOnMapPage={isOnMapPage} toggleMenu={toggleExpandCollapse} navigateHome={navigateHome} setIsOnMapPage={setIsOnMapPage} />
            </animated.div>
            {!isOnMapPage ? (
              <div className="roomArrowNames">
                <div className="arrowButton arrowLeft" onClick={() => changeRoom(false)}>
                  <img src="/img/Icons/backCarat.svg" className="arrow" />
                </div>
                {state.isSideNavExpanded ? (
                  <div className="roomName">
                    {currentRoom?.fields?.roomName}
                  </div>
                ) : (
                  ""
                )}
                <div className="arrowButton arrowRight" onClick={() => changeRoom(true)}>
                  <img src="/img/Icons/forwardCarat.svg" className="arrow" />
                </div>
              </div>
            ) : (
              ""
            )}
            <animated.div className="horizontalLine" style={hrSpring} />
            <animated.div style={energyTextSpring}>
              <div className="energyTitle">EXPLORE ENERGY TOPICS</div>
              <div className="energySubTitle">
                {isOnMapPage ?
                <div>Click a topic below to show in <br /> the home.</div> :
                <div>Click a topic to highlight markers <br /> in the room. Pan around the <br /> room to see more markers.</div>}
              </div>
            </animated.div>
            {buttons}
            <div className="progress" onClick={showProgress}>
              {state.isSideNavExpanded ? "See My Progress " : ""}
              <img src="/img/Icons/ellipsis.svg" className="ellipsis" />
            </div>
            <animated.hr className="horizontalLine" style={hrSpring} />
            <div className="navSocial">
              <SocialLinks {...{ sharelink }} />
            </div>
          </div>
        ) : (
          ""
        )}
      </animated.div>
      {!isOnMapPage || (window.innerWidth <= 1008 && state.isSideNavVisible) ? (
        <animated.div
          style={collapseIconSpring}
          className="collapseIcon"
          onClick={() => toggleExpandCollapse(window.innerWidth <= 1008)}
        >
          <animated.div style={iconSpring} className="arrowIcon">
            <img src="/img/Icons/collapse.svg" />
          </animated.div>
        </animated.div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SideNav;
