import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { constants } from "./constants";

function ProgressRing(props) {
  const isSideNav = props.progressRing.ringStyle == constants.ringType.side;

  const getProgressStyles = (isComplete) => {
    return props.progressStyles || {
      root: {},
      path: {
        stroke: getDisplayColor(false),
        strokeLinecap: "round",
        transition: "stroke-dashoffset 0.5s ease 0s",
        transform: "rotate(0.5turn)",
        transformOrigin: "center center",
        strokeWidth: getStrokeWidth(isComplete),
      },
      trail: {
        stroke: "#d6d6d6",
        strokeLinecap: "round",
        transform: "rotate(0.5turn)",
        transformOrigin: "center center",
        strokeWidth: getStrokeWidth(isComplete),
      },
      background: {
        fill: getFillColor(isComplete),
      },
    };
  };

  const getStatusStyle = (isComplete) => {
    return {
      height: "25px",
      width: isComplete ? "25px" : "40px",
      borderRadius: isComplete ? "50%" : "30%/50%",
      fontSize: isComplete ? "13px" : "11px",
      color: "white",
      background: "#1E3575",
      marginLeft: isComplete ? 25 : 32,
      bottom: 0,
      right: isComplete ? "-4px" : "-19px",
      textAlign: "center",
      position: "absolute",
      overflow: "visible",
    };
  };

  const getStrokeWidth = (isComplete) => {
    if (isSideNav){
      return "6px";
    }
    return isComplete ? "2px" : "4.5px";
  }

  const getDisplayColor = (isIcon) => {
    if (isIcon && isSideNav) {
      return "white";
    }

    // color is returned as a filter for SVG icon coloring. first two filters
    // (brightness & saturate) change icon to black before filtering to the desired color
    switch (props.progressRing.type) {
      case constants.markerType.tips:
        return isIcon ? "brightness(0) saturate(100%) invert(95%) sepia(58%) saturate(521%) hue-rotate(95deg) brightness(88%) contrast(90%)" : "#80D8D3";
      case constants.markerType.marketplace:
        return isIcon ? "brightness(0) saturate(100%) invert(70%) sepia(15%) saturate(2016%) hue-rotate(153deg) brightness(84%) contrast(84%)" : "#39AAC8";
      case constants.markerType.specialOffer:
        return isIcon ? "brightness(0) saturate(100%) invert(53%) sepia(58%) saturate(3642%) hue-rotate(190deg) brightness(104%) contrast(105%)" : "#2E9CFF";
      case constants.markerType.smartHome:
        return isIcon ? "brightness(0) saturate(100%) invert(15%) sepia(88%) saturate(2600%) hue-rotate(221deg) brightness(110%) contrast(105%)" : "#103DBC";
    }
  }

  const getFillColor = (isComplete) => {
    if (isComplete || (isSideNav && props.isRingActive)) {
      switch (props.progressRing.type) {
        case constants.markerType.tips:
          return "#F0FFFE";
        case constants.markerType.marketplace:
          return "#DCF1F7";
        case constants.markerType.specialOffer:
          return "#E9F2FA";
        case constants.markerType.smartHome:
          return "#ECEFFA";
      }
    }

    return "white";
  }

  const getInnerIconStyle = () => {
    if (isSideNav) {
      return {
        backgroundColor: getDisplayColor(false),
        width: 30,
        height: 30,
        borderRadius: "50%",
        overflow: "hidden",
        whiteSpace: "nowrap",
      };
    }
    return {};
  };

  return (
    <CircularProgressbarWithChildren
      value={props.progressRing.value}
      styles={getProgressStyles(props.progressRing.isComplete)}
      background={true}
      strokeWidth={3.5}
    >
      <div style={getInnerIconStyle()}>
        <img
          style={{ width: props.iconWidth ?? 20, marginTop: props.marginTop ?? 5, filter: getDisplayColor(true) }}
          src={props.progressRing.imgSrc}
        />
      </div>
      {props.showCount ?? (
        <div style={getStatusStyle(props.progressRing.isComplete)}>
          {props.progressRing.isComplete ? (
            <div className="innerCheck">
              <img src="img/Icons/checkmark.svg" />
            </div>
          ) : (
            <div className="inner">
              {props.progressRing.currentProgress.visited}/
              {props.progressRing.currentProgress.total}
            </div>
          )}
        </div>
      )}
    </CircularProgressbarWithChildren>
  );
}

export default ProgressRing;
