import { constants } from "./components/Common/constants";

function map(name) {
  switch (name) {
    case "living":
      return {
        id: constants.roomMap.find((x) => x.name === "living").id,
        displayName: "Living Room",
      };
    case "kitchen":
      return {
        id: constants.roomMap.find((x) => x.name === "kitchen").id,
        displayName: "Kitchen",
      };
    case "child":
    case "bedroom":
      return {
        id: constants.roomMap.find((x) => x.name === "bedroom").id,
        displayName: "Childs Bedroom",
      };
    case "entertainment":
    case "bonus":
      return {
        id: constants.roomMap.find((x) => x.name === "bonus").id,
        displayName: "Entertainment Room",
      };
    case "bathroom":
      return {
        id: constants.roomMap.find((x) => x.name === "bathroom").id,
        displayName: "Bathroom",
      };
    case "basement":
      return {
        id: constants.roomMap.find((x) => x.name === "basement").id,
        displayName: "Basement",
      };
    case "office":
      return {
        id: constants.roomMap.find((x) => x.name === "office").id,
        displayName: "Office",
      };
    case "garage":
      return {
        id: constants.roomMap.find((x) => x.name === "garage").id,
        displayName: "Garage",
      };
    case "attic":
    case "roof":
      return {
        id: constants.roomMap.find((x) => x.name === "roof").id,
        displayName: "Attic",
      };
    case "exterior":
    case "patio":
      return {
        id: constants.roomMap.find((x) => x.name === "exterior").id,
        displayName: "Exterior",
      };
    default:
      return {
        id: -1,
      };
  }
}

export default map;
