import { ActionType } from "./actions";

function reducer(state, action) {
  switch (action.type) {
    case ActionType.TOGGLE_PROGRESS_MODAL:
      return {
        ...state,
        isProgressModalOpen: !state.isProgressModalOpen,
      };
    case ActionType.TOGGLE_VIDEO_MODAL:
      return {
        ...state,
        isVideoModalOpen: !state.isVideoModalOpen
      };
    case ActionType.SET_VIDEO_DATA:
      return {
        ...state,
        video: action.payload
      }
    case ActionType.UPDATE_VISTED_MARKERS:
      return {
        ...state,
        visitedMarkers: action.payload.visitedMarkers,
        hasClickedMarker: true,
        firstMarkerType:
          action.payload.firstMarkerType ?? state.firstMarkerType,
        completedMarkerTypes: action.payload.completedMarkerTypes,
      };
    case ActionType.UPDATE_PROGRESS_TRACKER:
      switch (action.payload.tracker) {
        case "treesPlanted":
          return {
            ...state,
            treesPlanted: state.treesPlanted + action.payload.amount,
          };
        case "bagsOfWasteRecycled":
          return {
            ...state,
            bagsOfWasteRecycled: state.bagsOfWasteRecycled + action.payload.amount,
          };
        case "gallonsOfGasSaved":
          return {
            ...state,
            gallonsOfGasSaved: state.gallonsOfGasSaved + action.payload.amount,
          };
      }
    case ActionType.SET_ROOM_AS_VISITED:
      return {
        ...state,
        hasVisitedRoom: true,
      };
    case ActionType.DISMISS_MARKER_MODAL:
      return {
        ...state,
        hasDismissedMarkerModal: true,
      };
    case ActionType.SHOW_COMPLETED_MODAL:
      return {
        ...state,
        isCompletedModalOpen: true,
        currentCompletedMarkerType: action.payload,
      };
    case ActionType.DISMISS_COMPLETED_MODAL:
      return {
        ...state,
        isCompletedModalOpen: false,
      };
    case ActionType.SET_CURRENT_ROOM_ID:
      return {
        ...state,
        currentRoomId: action.payload,
      };
    case ActionType.TOGGLE_SIDE_NAV_EXPANDED:
      return {
        ...state,
        isSideNavExpanded: !state.isSideNavExpanded,
      };
    case ActionType.SHOW_SIDE_NAV:
      return {
        ...state,
        isSideNavVisible: true,
      };
    case ActionType.HIDE_SIDE_NAV:
      return {
        ...state,
        isSideNavVisible: false,
      };
    case ActionType.SET_MARKER_FILTER_TYPE:
      return {
        ...state,
        filterType: action.payload,
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionType.UPDATE_MAP_PAGE_CHANGE:
      return {
        ...state,
        mapPageChange: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
