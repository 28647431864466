import React from 'react';

const jsx = {
  ify: (node, idx) => {
    switch (node.nodeType.toLowerCase()) {
      case 'unordered-list':
        return <ul>{node.content.map((nc, ix) => jsx.ify(nc, ix))}</ul>
      case 'ordered-list':
        return <ol>{node.content.map((nc, ix) => jsx.ify(nc, ix))}</ol>
      case 'list-item':
        return <li key={idx}>{node.content.map((nc, ix) => jsx.ify(nc, ix))}</li>
      case 'paragraph':
        return <div key="idx" className="text">{node.content.map(nc => jsx.ify(nc))}</div>
      case 'hyperlink':
        return <a href={node.data.uri} className="textlink" target="_blank">{node.content.map(nc => jsx.ify(nc))}</a>
      default: //case 'text'
        return node.value
    }
  },

  capitalize: title => {
    if (title.indexOf(' ') > -1) {
      return title.split(' ').map(seg => seg.substring(0, 1).toUpperCase() + seg.substring(1).toLowerCase()).join(' ');
    }
    return title.substring(0, 1).toUpperCase() + title.substring(1).toLowerCase();
  }
}

export default jsx;
