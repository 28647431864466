import React, { useContext } from "react";
import "./CompletedModal.css";
import DteModal from "../../Common/DteModal/DteModal";
import { AppContext } from "../../../store/context";
import { ActionType } from "../../../store/actions";
import ProgressRingsService from "../../../ProgressRingsService";
import ProgressRing from "../../Common/ProgressRing";
import { constants } from "../../Common/constants";

function CompletedModal() {
  const { state, dispatch } = useContext(AppContext);
  let isFullyCompleted = state.completedMarkerTypes.length == 4;

  var progressRing = ProgressRingsService(state, constants.ringType.modal).find(
    (x) => x.type == state.currentCompletedMarkerType
  );

  const dismissCompletedModal = () => {
    dispatch({ type: ActionType.DISMISS_COMPLETED_MODAL });
  };

  const seeProgressClick = () => {
    dispatch({ type: ActionType.DISMISS_COMPLETED_MODAL });
    dispatch({ type: ActionType.TOGGLE_PROGRESS_MODAL });
  };

  return (
    <DteModal
      isOpen={state.isCompletedModalOpen}
      onClose={dismissCompletedModal}
      width={"450px"}
      height={"auto"}
    >
      <div className="completedModalContent">
        {isFullyCompleted ? <div className="fullyCompletedIcon"><img src="img/Icons/fullyCompleted.svg"/></div> :
        <div>
          {progressRing ? (
            <div className="ring">
              <ProgressRing progressRing={progressRing} />
            </div>
          ) : (
            ""
          )}
        </div>}
        <div className="modalTitle">
          {isFullyCompleted ? "Congratulations!" : "You've Completed A Badge!"}
        </div>
        <div className="markerSubTitle">
          {isFullyCompleted
            ? "Way to Go! You discovered home efficiency routines to help lower your energy bill!"
            : "Way to Go! You discovered home efficiency routines to help lower your energy bill!"}
        </div>
        <div className="modalButton" onClick={seeProgressClick}>
          See My Progress
        </div>
      </div>
    </DteModal>
  );
}

export default CompletedModal;
