import React, { useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import "./MiniMap.css";
import { AppContext } from "../../store/context";
import cms from '../../cmsservice';
import { constants } from "../Common/constants";
import map from "../../roommap";

function MiniMap({ room, isOnMapPage, toggleMenu, navigateHome, setIsOnMapPage }) {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const [mmpath, setMmpath] = useState("");
  const [housePath, setHousePath] = useState("");
  const [backNavPath, setBackNavPath] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    //get season
    cms.getEntry('4gbEkpYveOL3YdTF0t7qpg').then(({ fields: { hotSeasonStartDate, hotSeasonEndDate }}) => {
      if (hotSeasonStartDate && hotSeasonEndDate) {
        const dates = [hotSeasonStartDate, hotSeasonEndDate].reduce((acc, itm) => {
          let d = new Date(itm);
          d.setFullYear(new Date().getFullYear());
          return [ ...acc, d.getTime() ];
        }, []);
        const now = new Date().getTime();
        if (dates[0] < now && dates[1] > now) setHousePath(process.env.PUBLIC_URL + "/assets/minimap/miniHouse.svg");
        else setHousePath(process.env.PUBLIC_URL + "/assets/minimap/miniHouseCold.svg");
      }
      else {
        setHousePath(process.env.PUBLIC_URL + "/assets/minimap/miniHouseCold.svg");
      }
    });

    setMmpath(
      process.env.PUBLIC_URL + "/assets/minimap/minimap" + room + ".svg"
    );
    setBackNavPath(process.env.PUBLIC_URL + "/img/Icons/backNav.svg");
  }, [room]);

  const goHome = () => {
    history.push("/");
    setIsOnMapPage(true);
    if (window.innerWidth <= 1008 || !state.isSideNavExpanded) {
      toggleMenu();
    }
    navigateHome();
    constants.gmt({
      state,
      dataTrackDetail: constants.analytics.home,
      dataTrackAction: constants.analytics.click,
      dataTrackSubaction: constants.roomMap.filter(r => r.id === room).map(r => map(r.name).displayName)[0] || 'Unknown',
    });
  }

  return (
    <div className="outer">
      <div className={isOnMapPage ? "outerHouse" : "outerMiniMap"} onClick={!isOnMapPage ? goHome : null}>
        <div className={isOnMapPage ? "MiniMapHouse" : "MiniMap"}>
          <img src={isOnMapPage ? housePath : mmpath} className={isLoaded ? "mmImg showImg" : "mmgImg hideImg"} onLoad={() => setIsLoaded(true)} onError={() => setIsLoaded(true)} />
        </div>
      </div>
      {!isOnMapPage && state.isSideNavExpanded ? <img src={backNavPath} alt="backNav" className="backNav" onClick={!isOnMapPage ? goHome : null} /> : ""}
    </div>
  );
}

export default MiniMap;
