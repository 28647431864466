export const initialState = {
  isProgressModalOpen: false,
  isVideoModalOpen: false,
  video: null,
  visitedMarkers: [],
  hasVisitedRoom: false,
  hasClickedMarker: false,
  hasDismissedMarkerModal: false,
  firstMarkerType: "",
  completedMarkerTypes: [],
  currentCompletedMarkerType: "",
  isCompletedModalOpen: false,
  isSideNavVisible: false,
  isSideNavExpanded: window.innerWidth > 800,
  currentRoomId: -1,
  filterType: "none",
  loading: null,
  mapPageChange: null,
  roomHasBeenVisited: false,
  treesPlanted: 0,
  bagsOfWasteRecycled: 0,
  gallonsOfGasSaved: 0
};
